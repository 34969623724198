/**
 * Imported from Typekit:
 * Proxima Nova:
 *    Bold (700) + Bold Italic & Black (900) + Black Italic
 *    font-family: 'proxima-nova';
 * Freight Pro:
 *    Book (400) + Book Italic & Bold (700) + Bold Italic
 *    font-family: 'freight-text-pro';
 */

$proxima: 'proxima-nova', sans-serif;
$freight: 'freight-text-pro', serif;
