@import '~styles/_fonts';
@import '~styles/_colors';

.single {
  font-family: $proxima;
}

.name {
  font-weight: 700;
}

.timestamp {
  padding-left: 10px;
  color: $greyTextDark;
  font-size: 14px;
}

.message {
  font-size: 14px;
}
