@import '~styles/_colors';
@import '~styles/_borders';
@import '~styles/_fonts';
@import '~styles/_breakpoints';

.input {
  background-color: white;
  @include border-input();
  border-right: none;
  height: 45px;
  width: calc(100% - 64px);
  padding: 0 15px;
  font-size: 14px;
  font-weight: 300;
  color: $greyTextDark;

  &:focus {
    outline-width: 0;
    color: darken($greyTextDark, 20%);
    border-color: darken($greyText, 20%);
  }

  @include breakpoint(laptop-up) {
    height: 55px;
    width: calc(100% - 88px);
  }
}

.fieldContainer {
  position: relative;
}

.field {
  position: absolute;
  top: 0;
  left: 0;
}

.button {
  width: 64px;
  height: 45px;
  top: 0;
  right: 0;
  position: absolute;
  border: none;
  font-family: $proxima;
  font-weight: 500;
  color: white;
  font-size: 16px;
  transition: 0.2s all;
  padding: 0;
  padding-top: 4px;
  background-color: $orangePrimary;
  border-color: $orangePrimary;

  &:hover {
    cursor: pointer;
    background-color: $orangePrimaryHover;
  }

  &:active {
    background-color: $orangePrimaryActive;
  }

  @include breakpoint(laptop-up) {
    height: 55px;
    width: 88px;
  }
}

.button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.excerpt {
  font-family: $proxima;
  margin: 15px 0;
}

.resultType {
  color: $greyTextDark;
  font-style: italic;
}
