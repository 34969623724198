@import '~styles/fonts';

.noticeSubtext {
  text-align: center;
  font-family: $freight;
}

.buttonWrapper {
  max-width: 400px;
  margin: auto;
  padding-top: 20px;
}
