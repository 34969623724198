@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';

.wrapper {
  text-align: left;
  @include breakpoint(laptop-up) {
    text-align: center;
  }
}

.img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.caption {
  font-family: $proxima;
  color: $greyTextDark;
  font-style: italic;
  display: inline-block;
  text-align: left;
  position: relative;
  text-align: left;
  padding-left: 15px;
  margin-top: 10px;
}

.caption:before {
  content: '';
  position: absolute;
  top: -5px;
  left: 0;
  bottom: -3px;
  width: 5px;
  background-color: $navyPrimary;
}
