@import '~styles/_colors';
@import '~styles/_breakpoints';
@import '~styles/_fonts';

.outerWrapper {
  overflow: auto;
}

.wrapper {
  border: 1px solid $greyText;
  max-width: 767px;
  font-family: $proxima;
  margin: 20px auto 30px;
  @include breakpoint (tablet-up) {
    margin-bottom: 50px;
  }
  @include breakpoint (laptop-up) {
    margin-bottom: 100px;
  }
}
