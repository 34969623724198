@import '~styles/_breakpoints';
@import '~styles/_fonts';

.institutions {
  font-family: $proxima;
  @include breakpoint (tablet-up) {
    text-align: left;
    columns: 2;
  }
  @include breakpoint (laptop-up) {
    columns: 3;
  }
  @include breakpoint (desktop-up) {
    font-size: 18px;
  }
  p {
    margin: 0;
  }
}
