@import '~styles/_breakpoints';
@import '~styles/_spacing';

.combo {
  display: table;
  width: 100%;
  padding: 0;
  line-height: 1;
}

.first,
.last {
  display: table-cell;
  vertical-align: top;
}

.first {
  width: 0;
  padding-right: $comboGutter;
  line-height: 0;
}

.last {
  width: 100%;
  line-height: 1.6;
}

.last.center {
  width: auto;
}

/* Reverse */

.reverse > .first {
  width: 100%;
  line-height: 1.6;
}

.reverse > .last {
  width: 0;
  white-space: nowrap;
  line-height: 0;
}

/* Vertical alignments - middle */

.middle > * {
  vertical-align: middle;
}

/* Vertical alignments - middle */

.bottom > * {
  vertical-align: bottom;
}

/* Combo.propTypes.wide */
.wide > .first {
  padding-right: $comboWideGutter;
}

/* Combo.props.mobileReset */
.combo.mobileReset > div {
  @include breakpoint(mobile-only) {
    display: block;
    width: 100%;
    white-space: normal;
    line-height: 1;
  }
}
