@import '~styles/_colors';

.aside {
  font-size: 12px;
  font-weight: 300;
  color: $greyDark;
  display: block;
  margin-bottom: 10px;
}

.inline {
  display: inline;
  margin-left: 5px;
}
