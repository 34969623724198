@import "~styles/_breakpoints";
@import "~styles/_mixins";

.container {
  max-width: 440px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 18px 20px;
  border-radius: 3px;
  margin: 0 auto;
}

.infoIcon {
  height: 38px;
  width: 38px;
  flex: 0 0 auto;
  position: relative;
  border-radius: 50%;
  > * {
    position: absolute;
    @include centerBothAbsolute();
  }
  @include breakpoint(mobile-only) {
    height: 30px;
    width: 30px;
    align-self: flex-start;
  }
}

.textStyle {
  margin-left: 22px;
  line-height: 1.38;
  @include breakpoint(mobile-only) {
    text-align: left;
  }
}
