@import '~styles/_breakpoints';

.banner {
  background-color: #fff;
  padding: 30px 0 0 30px;
  
  @include breakpoint(mobile-only) {
    padding: 30px 0 0;
  }
}

.wrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  
  @include breakpoint(laptop-up) {
    flex-direction: row;
    align-items: center;
  }
}

.subscribeContainer {
    max-width: 425px;
    padding: 16px 19px 14px 16px;
    border-radius: 3px;
    margin-top: 20px;
    background-color: #f0f0fa;
    @include breakpoint(laptop-up) {
      text-align: center;
      margin-top: initial;
    }
    @include breakpoint(mobile-only) {
      display: none;
    }
}

.subscribeText {
  display: flex;
  align-items: center;
  height: 20px;
  margin: 0 0 3px 0;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #242424;
  @include breakpoint(laptop-up) {
    text-align: center;
  }
}

.chevronWrapper {
  display: flex;
  align-items: center;
  margin: 0 0 0 9px;
  width: 23px;
  height: 23px;
}
.subscribeText a {
  color: #383cbc;
}