@import '~styles/_breakpoints';
@import '~styles/_breakpoint-variables';

.root {
  width: 100%;
}

.formWrapper {
  padding: $padding-mobile;
  flex: 3;
  width: 100%;
}

.leftColumn {
  flex: 7;
  width: 100%;
  text-align: left;
}

.title {
  line-height: 1.4;
  margin-top: 0;
  font-weight: 500;
}

.subtitle {
  margin-top: 0;
  line-height: 1.4;
}

.wrapper {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-direction: column;

  @include breakpoint (laptop-up) {
    flex-direction: row;
  }

  &.twoColumnWrapper {
    justify-content: space-between;
  }
}
