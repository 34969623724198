@import "~styles/_breakpoints";
@import '~styles/_breakpoint-variables';
@import '~styles/_colors';

.mainFormWrapper {
  text-align: left;
  padding: $padding-mobile;
}

.container {
  background-color: #ffffff;
  padding-bottom: 75px;
}

.container h3 {
  text-align: center;
  @include breakpoint(laptop-up) {
    margin-bottom: 45px;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @include breakpoint(laptop-up) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.subscribeForm {
  padding: 25px 25px 26px 22px;
  border-radius: 3px;
  background-color: #383cbc;
  color: white;
  font-size: 14px;
  @include breakpoint(phablet-up) {
    width: 382px;
  }
  @include breakpoint(phablet-only) {
    width: 382px;
  }
  @include breakpoint(mobile-only) {
    max-width: 382px;
  }
}

input[type="text"],
input[type="password"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[class*="inputError"] {
  border: solid 2px #be0000;
}

.spacer {
  margin-bottom: 10px;
}

.formFooterNote {
  padding-top: 20px;
  width: 100%;
  font-size: 12px;
  font-weight: normal;
  line-height: 2.5;
  text-align: center;
}

.errorMessageBox {
  display: inline-block;
  width: 100%;
  margin-bottom: 6px;
  padding: 8px 5px 5px 11px;
  border-radius: 3px;
  background-color: #fbb5b5;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #921313;
}

.titleWrapper {
  margin-top: 50px;
}
.programUpdatesTitle {
  margin-top:15px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #2d2d2d;
  @include breakpoint(mobile-only) {
    text-align: center;
  }

  @include breakpoint(phablet-only) {
    text-align: center;
    margin: 15px 30px 0 30px;
  }
}

.programUpdatesBody {
  @include breakpoint(phablet-only) {
    padding-left: 50px
  }
}

.checkmark {
  display: inline-block;
  height: 20px;
  width: 20px;
  @include breakpoint(phablet-up) {
    height: 21px;
    width: 21px;
  }
  border-radius: 50%;
  background-color: $orangePrimary;
  position: relative;
}

.checkmark:after {
  content: '';
  position: absolute;
  border: 1px solid white;
  border-top-width: 0;
  border-right-width: 0;
  height: 4px;
  width: 9px;
  top: 7px;
  left: 6px;
  @include breakpoint(phablet-up) {
    top: 8px;
  }
  transform: rotate(-50deg);
}

.checklistItem {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2d2d2d;
  text-align: left;
  padding: 8px 0;
  p {
    margin: 0;
  }
}

.programUpdates {
  justify-self: self-start;
  font-size: 16px;

  @include breakpoint(laptop-up) {
    width: 415px;
    margin-right: 114px;
  }
}

.programUpdates h5 {
    font-size: 18px;
}

.programUpdates ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.programUpdates ul li {
    height: 40px;
}

.programUpdates svg {
    margin-right: 11px;
    vertical-align: sub;
    width: 20px;
    height: 20px;
}

.thankYouFlexContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.envelopeImage {
  width: 80px;
}

.subscriptionConfirmed {
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d2d2d;
}