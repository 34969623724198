@import '~styles/_fonts';
@import '~styles/_colors';

.wrapper {
  font-family: $proxima;
  background-color: darken($redPrimary, 30);
  color: #eee;
  text-align: center;
  padding: 20px;
  border-bottom: 1px darken($redPrimary, 35) solid;
}
