@import '~styles/_breakpoints';
@import '~styles/_colors';

.notification {
  background-color: $lightBlue;
  color: white;
  padding: 20px 0;
  @include breakpoint(laptop-up) {
    padding: 30px 0;
  }
  &:not(:first-child) {
    border-top: 1px solid transparentize($navySecondary, 0.3);
  }
}

.button {
  margin: 15px auto 0;
  @include breakpoint(laptop-up) {
    min-width: 270px;
    margin-top: 0;
  }
}
