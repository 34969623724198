@import '~styles/_colors';
@import '~styles/_breakpoints';

.horizontalRule {
  border: 0;
  border-top: 3px solid $greyText;
  width: 37px;
  @include breakpoint(laptop-up) {
    width: 56px;
  }
}

/* HorizontalRule.props.full */
.horizontalRule.full {
  border-width: 1px;
  width: 100%;
}

/* HorizontalRule.props.dashed */
.horizontalRule.dashed {
  border-width: 1px;
  width: 100%;
  border-top: 2px dashed #dadada;
}

.orange {
  border-color: $orangePrimary;
}
