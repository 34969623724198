@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_zindex';

.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: transparentize(white, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $zModal;
}

.modal {
  border: 1px solid $greyText;
  position: relative;
  box-shadow: 2px 2px 2px 0 transparentize(black, 0.95);
  background-color: white;
  outline: none;
  max-height: 90%;

  padding: 10px;
  width: 90%;
  @include breakpoint(tablet-up) {
    padding: 25px;
    width: auto;
  }
  @include breakpoint(laptop-up) {
    padding: 60px 77px;
  }
}
.overflowY {
  overflow-y: scroll;
}

.borderlessModal {
  border: 1px solid $greyText;
  position: relative;
  box-shadow: 2px 2px 2px 0 transparentize(black, 0.95);
  background-color: white;
  outline: none;
  max-height: 99%;
  width: 90%;
  @include breakpoint(tablet-up) {
    width: auto;
  }
  @include breakpoint(phablet-only) {
    margin: 0 15px 0 15px;
  }
}

.btnmedium {
  width: 30px;
  height: 30px;
}

.btnsmall {
  width: 24px;
  height: 24px;
  @include breakpoint(phablet-up) {
    width: 27px;
    height: 27px;
  }
}

.closeButton {
  position: absolute;
  display: block;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
}

.btncornerclose {
  top: 15px;
  right: 15px;
}

.btncornermedium {
  top: 32px;
  right: 32px;
}
.modalWrapper {
  font-family: $proxima;
  max-width: 700px;
  a {
    font-weight: 600;
    color: $orangePrimary;
  }
}

.contact {
  @include breakpoint (laptop-up) {
    position: absolute;
    top: 28px;
    left: 32px;
  }
  font-weight: 500;
  font-size: 14px;
  color: $greyTextMed;
  strong {
    color: black;
  }
  max-width: 50%; // ensure we don't overlap with close button
}

.content {
  padding: 30px 10px 10px;
  @include breakpoint (laptop-up) {
    padding: 40px 30px 10px;
  }
}
