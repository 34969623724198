@import '~styles/_borders';
@import '~styles/_breakpoints';
@import '~styles/_breakpoint-variables';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_mixins';
@import '~styles/_shadows';
@import '~styles/_spacing';
@import '~styles/_zindex';

@import '~core/components/Card/_collection';

.card {
  background: white;
  @include border-cards;
  padding: $padding-mobile;
  /* When stacking several cards, ensures we're not generating two borders */
  border-bottom: none;
  @include shadow-cards;
  @include breakpoint(mobile-only) {
    margin: 0 16px;
    @include border-cards;
  }

  &:last-of-type {
    @include border-cards;
    border-bottom: 1px solid $greyText;
  }

  @include breakpoint(tablet-up) {
    padding: $padding-tablet;
  }
}

.card.spacing {
  @include border-cards;
}

.background {
  @include backgroundAttachmentCoverCenter();
  position: relative;
  color: white !important;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include gradient-navy();
  }
  * {
    z-index: $zResetContentAbovePseudoMask;
  }
}

/* Card.props.noShadow */
.noShadow {
  box-shadow: none;
}

/* Card.props.fullHeight */
.fullHeight {
  height: 100%;
}

/* Card.props.spacing */
.spacing {
  margin-bottom: $gridGutter;
  height: calc(100% - #{$gridGutter});
  @include breakpoint(mobile-only) {
    margin-bottom: 0;
    height: 100%;
  }
}

/* Card.props.spacingMobile */
.spacingMobile {
  @include breakpoint(mobile-only) {
    height: auto;
    margin-bottom: $padding-mobile;
  }
}

/* Card.props.spaceBetween */
.spaceBetween {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Card.props.color */
.blue {
  color: white;
  @include gradient-content-grid();
}
