@import '~styles/_colors';
@import '~styles/_mixins';
@import '~styles/_breakpoints';
@import '../../_mixins';

$menu-width: 275px;
$menu-padding: 30px;
$link-padding: 8px;
$link-padding-desktop: 18px;
$chevron-width: 12px;

.menuSection {
  display: table;
}

.dropdownHeader {
  display: table-cell;
  text-align: center;
  white-space: nowrap;
  font-weight: 500;
  position: relative;

  .dropdownLink {
    padding: 0 $link-padding;
    position: relative;
    @include linkHover();
    @include breakpoint(desktop) {
      padding: 0 $link-padding-desktop;
    }
  }

  @include dropdownMenuButton(dropdown);

  .dropdown {
    @include dropdownMenuWrapper($menu-width);
    /*
     * Override menu positioning so x-center of menu is aligned w/ middle of chevron.
     * Achieved by setting right positioning to fall at half the menu width, minus
     * the padding on the link and half the chevron width.
     */
    right: calc(-#{$menu-width} / 2 + #{$link-padding} + #{$chevron-width} / 2);
    font-size: 16px;
    white-space: normal;

    > ul {
      @include dropdownMenuContentArrowCenter($menu-width);
      padding: $menu-padding;
    }
  }

  ul {
    @include listReset();
    text-align: left;
  }

  li {
    margin: 0;
    padding: 0;
  }
}

.item {
  font-weight: 500;
}

.child {
  font-weight: 300;
}

.dropdownHeader svg {
  margin-left: 6px;
}
