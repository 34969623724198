@import '~styles/_colors';
@import '~styles/_fonts';

.loginPageContainer {
  padding: 70px 0;
}

.errorText {
  font-family: $proxima;
  font-size: 14px;
  margin: 0.2em 0 0.6em;
  height: 1em;
}

.contactUsLink {
  color: $bluePrimary;
}

.link {
  text-align: center;
}
