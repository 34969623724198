@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';

.wrapper {
  @include breakpoint(laptop-up) {
    max-width: 80%;
    margin: 0 auto;
  }
}

.testimonial {
  margin-bottom: 30px;
  > p {
    margin: 0;
    line-height: 2;
  }
  @include breakpoint(phablet-up) {
    margin-bottom: 55px;
  }
}

.quote {
  @include breakpoint(phablet-up) {
    font-family: $freight;
    display: inline-block;
    font-size: 60px;
    margin: 0;
    line-height: 0.5;
    font-weight: 600;
  }
}

.inlineCite {
  text-align: left;
  width: 250px;
  margin: 0 auto;
  margin-top: 30px;
}

.cites {
  &:hover {
    cursor: pointer;
  }

  @include breakpoint (phablet-up) {
    margin: 0 auto;
  }
}

.cite {
  display: inline-block;
  @include breakpoint (phablet-up) {
    width: calc(100% / 3);
    text-align: left;
    vertical-align: top;
    padding-right: 10px;
  }
}

.citeNav {
  opacity: 0.5;
  transition: 0.2s all;
  &.active {
    opacity: 1;
  }
}

.dots {
  height: 10px;
  width: 10px;
  margin: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: $greyText;
  transition: 0.2s all;
  &.active {
    background-color: $orangePrimary;
  }
}

.linkWrapper {
  margin-top: 10px;
  font-family: $proxima;
  @include breakpoint(phablet-up) {
    margin-top: 25px;
  }
}

/* Hack Ahead:
 * Hard-coding a min-height in order to manage DOM jump w/
 * different testimonial length
 */
.testimonial > p {
  min-height: 310px;
  @include breakpoint (tablet-up) {
    min-height: 250px;
  }
  @include breakpoint (phablet-up) {
    min-height: 140px;
  }
  @include breakpoint (laptop-up) {
    min-height: 160px;
  }
}
