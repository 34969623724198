@import '~styles/_colors';

.loadingScreen {
  position: absolute;
  height: 100%;
  width: 100%;
}

.loadingSpinner {
  height: 50px;
  width: 50px;
  position: absolute;
  left: 50%;
  margin-left: -25px;
  top: 30%;
  margin-top: -25px;

  stop {
    stop-color: $orangePrimary;
  }
}

.serverIsDownPage {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}
