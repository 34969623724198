.checkbox {
    font-size: 32px;
    margin: 0 8px;
}

.container {
    position: relative;
}

.checkboxContainer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 8px 0 0;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 1px;
    right: 8px;
}

.label {
    margin-bottom: 0;
}