@import '~styles/_breakpoint-variables';
@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_mixins';
@import '~styles/_zindex';

$height-tab: 60px;

@mixin activeTabStyles () {
  background-color: white;
  border: 1px solid $greyText;
  border-bottom: none;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    height: 4px;
    background-color: white;
    z-index: $zFilterTabsPseudo;
  }
}

.wrapper {
  font-weight: 500;
  @include breakpoint (phablet-up) {
    background-color: $greyBkgDark;
    margin-bottom: 30px;
    padding-top: 30px;
    border-bottom: 1px solid $greyText;
  }

  @include breakpoint(laptop-up) {
    padding-top: 50px;
  }
}

.tab {
  height: $height-tab;
  position: relative;
  font-family: $proxima;
  text-align: center;
  color: $greyTextDark;
  transition: background-color 0.2s, color 0s;
  &:hover {
    @include activeTabStyles();
  }
  span {
    position: absolute;
    white-space: nowrap;
    /* Push the spans down just a *bit* more from center. */
    @include centerBothAbsolute();
    top: 55%;
  }
}

.active {
  color: $blackPrimary;
  @include activeTabStyles();
}

.dropdownWrapper {
  @include breakpoint (mobile-only) {
    margin-left: -#{$padding-mobile};
    margin-right: -#{$padding-mobile};
  }

  @include breakpoint(tablet-up) {
    margin-top: $padding-tablet;
  }
}
