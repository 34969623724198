@import '~styles/_breakpoints';

.subscribeModal {
  margin-top: 45px;
  @include breakpoint (tablet-up) {
    margin-top: 30px;
  }
  @include breakpoint (laptop-up) {
    margin-top: 0;
  }
}
