@import '~styles/_breakpoints';
@import '~styles/_colors';

.totalAmountText,
.price {
  text-align: center;
  @include breakpoint (laptop-up) {
    text-align: left;
  }
}

.totalAmountText {
  color: $greyTextDark;
  font-size: 14px;
}

.price {
  display: block;
  font-size: 20px;
  margin-bottom: 10px;
  @include breakpoint (tablet-up) {
    line-height: 1.2em;
    font-size: 37px;
    margin-bottom: 0;
  }
}

.noticeBox {
  background-color: $greyBkgDark;
  font-size: 12px;
  text-align: left;
  padding: 14px;
  margin-top: 10px;

  p {
    margin-top: 0px;
    margin-bottom: 14px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
