@import '~styles/_breakpoints';

.wrapper {
  text-align: center;
  padding: 20px 0;

  @include breakpoint(tablet-up) {
    padding: 30px 0;
    .subtitle {
      margin: 30px auto 0;
      max-width: 475px;
    }
  }

  @include breakpoint(laptop-up) {
    .subtitle {
      max-width: 650px;
    }
  }
}
