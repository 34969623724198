@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_spacing';

.wrapper {
  height: 100%;
  font-family: $freight;
}

.information {
  p {
    margin-top: 0;
  }

  @include breakpoint(mobile-only) {
    text-align: left;
    min-width: 240px;
    max-width: 80%;
  }

  @include breakpoint(tablet-up) {
    // We do this to ensure the button is always on the bottom of card
    padding-top: 15px;
    min-height: calc(100% - #{$teamCustomAvatarTablet});
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @include breakpoint(laptop-up) {
    min-height: calc(100% - #{$teamCustomAvatarLaptop});
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.informationSpacing {
  margin-bottom: 20px;
}

.name,
.title,
.university {
  font-weight: 600;
  line-height: 1.2;

  @include breakpoint(laptop-up) {
    font-weight: 500;
  }
}

.name,
.title {
  margin-bottom: 5px;

  @include breakpoint(laptop-up) {
    margin-bottom: 10px;
  }
}

.title,
.university {
  font-style: italic;
}

.university {
  color: $greyTextDark;
  margin-bottom: 0;
}

.button {
  width: 130px;

  @include breakpoint(tablet-up) {
    width: 100%;
    width: 150px;
    margin: 0 auto;
  }

  @include breakpoint(laptop-up) {
    width: 140px;
  }
}

.bioModal {
  width: 100%;
  max-width: 600px;
  text-align: center;
  overflow-y: scroll;

  .name {
    font-size: 20px;
    @include breakpoint (tablet-up) {
      font-size: 22px;
    }
  }

  .title,
  .university,
  .bio {
    font-family: $proxima;
  }

  .hr {
    margin: 25px 0;
    @include breakpoint (tablet-up) {
      margin: 35px 0;
    }
  }

  .bio {
    text-align: left;
    padding: 15px;
    p {
      margin: 0;
    }
  }
}
