@import '~styles/_borders';
@import '~styles/_colors';
@import '~styles/_mixins';

.stripeWrapper {
  position: relative;
  height: 45px;
  transition: 0.2s all;
  @include border-input();
}

.stripeWrapper.active {
  border-color: darken($greyText, 20%);
}

.stripeWrapper.error {
  border-color: $redPrimary;
}

.stripeWrapper > div {
  position: absolute;
  padding: 0 15px;
  width: 100%;
  @include centerYAbsolute();
}

.stripeError {
  font-size: 14px;
  margin: 0.2em 0 0.6em;
  height: 1em;
}

.brand {
  position: absolute;
  height: 30px;
  width: 48px;
  right: 5px;
  margin: 0;
  @include centerYAbsolute();
  background-size: cover;
  background-position: center center;
}

.transactionErrorMessage {
  color: $redPrimary;
}
