@import '~styles/_colors';
@import '~styles/_fonts';

.link {
  color: inherit;
  text-decoration: none;
  font-family: inherit;
  transition: 0.2s all;
  &:hover {
    cursor: pointer;
  }
}

/* Link.props.sans */
.sans {
  font-family: $proxima;
}

/* Link.props.type === 'text' */
.text {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 5px;
  &:hover {
    background: $greyScreen;
  }
}

/* Link.props.fullWidth */
.fullWidth {
  width: 100%;
}

/* Link.props.orange */
.orange {
  color: $orangePrimary;
  border-bottom: 1px solid $orangePrimary;
  font-weight: 500;
  transition: 0.2s all;
  &:hover {
    color: $orangePrimaryHover;
    border-color: $orangePrimaryHover;
  }
}
