@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_mixins';

.heroHeader {
  position: relative;
  @include backgroundAttachmentCoverCenter();
  color: white;
}

.blueScreen {
  background-color: transparentize($navyPrimary, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.headerContent {
  position: absolute;
}
