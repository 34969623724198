@import '~styles/_borders';
@import '~styles/_breakpoints';
@import '~styles/_fonts';

$avatarDia: 55px;

/* PostCollection-specific styles */
.wrapper {
  padding: 20px 0 40px;

  p {
    font-family: $proxima;
  }

  @include breakpoint(laptop-up) {
    padding: 70px 0;
  }
}

/* PostSingle-specific styles */
.single {
  padding-bottom: 30px;
  margin-bottom: 30px;
  @include border-thin(bottom);

  @include breakpoint(tablet-up) {
  padding-bottom: 40px;
  margin-bottom: 40px;
  }
}

.date {
  margin: 20px 0;
}

.author {
  @include breakpoint(mobile-only) {
    padding: 25px 0;
  }
}

.footer {
  @include breakpoint(tablet-up) {
    margin-top: 30px;
  }
}

/* MondayMotivatorAside-specific style */
.aside {
  @include breakpoint(laptop-up) {
    padding-right: 60px;
  }
}

.note {
  @include breakpoint(tablet-only) {
    display: inline-block;
    width: calc(100% - #{$avatarDia});
    padding-left: 25px;
  }
}

.noteText {
  margin: 0;
}

.mondayMotivatorFooterText {
  font-family: $proxima;
  color: $greyDark;
  opacity: 0.8;

  img {
    margin: 20px 0;
  }
}
