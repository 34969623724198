@import '~styles/_borders';
@import '~styles/_colors';

.textarea {
  background-color: white;
  @include border-input();
  border-radius: 2px;
  width: 100%;
  padding: 15px;
  font-size: 14px;
  font-weight: 300;
  color: $greyTextDark;
  transition: all 0.4s;

  &:focus {
    outline-width: 0;
    color: darken($greyTextDark, 20%);
    border-color: darken($greyText, 20%);
  }

  &[type='password'] {
    font-size: 20px;
  }

  &[disabled] {
    background-color: $greyBkgDark;
  }
}

:global(.inputHasError) .textarea,
.globalError {
  border-color: $redPrimary;
}
