@mixin psuedo-border() {
  height: 1px;
  background: $greyText;
}

.tabs {
  position: relative;
  margin: -#{$padding-mobile};
  margin-bottom: $padding-mobile;

  @include breakpoint(tablet-only) {
    margin-left: -#{$padding-tablet};
    margin-right: -#{$padding-tablet};
    margin-bottom: $padding-tablet;
  }

  @include breakpoint(laptop-up) {
    margin-left: -#{$padding-desktop};
    margin-right: -#{$padding-desktop};
    margin-bottom: $padding-desktop;
    height: 70px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    @include psuedo-border();
  }

  ul {
    @include listReset();
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    @include breakpoint(laptop-up) {
      &:after {
        content: '';
        position: absolute;
        /* Ridiculous width declaration to ensure we maining full bleed on element */
        width: 2000px;
        /* Same reasoning as above 😱 */
        left: -1500px;
        /* Overlap existing underline */
        bottom: -1px;
        @include psuedo-border();
      }
    }
  }

  li {
    padding: 20px 10px;
    display: inline-block;
    font-family: $proxima;
    text-transform: capitalize;
    transition: 0.2s all;
    position: relative;
    cursor: pointer;
    color: $greyTextDark;

    @include breakpoint(tablet-only) {
      padding: $padding-mobile;
    }

    @include breakpoint(laptop-up) {
      padding-left: $padding-desktop;
      padding-right: $padding-desktop;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0;
      background: $blackPrimary;
      transition: 0.2s height;
    }
    &:first-of-type {
      padding-left: $padding-mobile;
      @include breakpoint(tablet-only) {
        padding-left: $padding-tablet;
      }
      @include breakpoint(laptop-up) {
        padding-left: $padding-desktop;
      }
    }
    &:last-of-type {
      padding-right: $padding-mobile;
      @include breakpoint(tablet-only) {
        padding-right: $padding-tablet;
      }
      @include breakpoint(laptop-up) {
        padding-right: $padding-desktop;
      }
    }
  }

  li.active {
    color: $blackPrimary;
    &:after {
      height: 5px;
      transition-timing-function: ease;
    }
  }
}

.body {
  min-height: 500px;
}
