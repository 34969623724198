@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_mixins';
@import '~styles/_spacing';

$headingMarginBottom: 50px;
$uiHeight: 225px;

.wrapper {
  background-image: url('./assets/fsp-header.png');
  @include backgroundAttachmentCoverCenter();
  color: white;
  text-align: center;
  overflow: hidden;
  position: relative;
  min-height: 480px;
  // To make up for the height of the global header
  padding-top: calc(#{$globalHeaderHeight} + 20px);
  // Ensure we don't crash into the UI asset on very narrow screens
  @include breakpoint(phablet-up) {
    text-align: left;
    min-height: 400px;
    // To make up for the height of the global header
    padding-top: calc(#{$globalHeaderHeight} + 50px);
  }
}

.wrapper:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $heroScreen;
}

.heading {
  margin-bottom: $headingMarginBottom;
  @include breakpoint(phablet-up) {
    margin-bottom: 50px;
  }
}

.session {
  position: relative;
  p {
    font-family: $proxima;
    margin: 0;
  }

  @include breakpoint(phablet-up) {
    padding-left: 25px;
  }
}

.session:before {
  content: '';
  position: absolute;
  background-color: $orangePrimary;
  @include centerXAbsolute();
  width: 40px;
  height: 3px;
  // Center in the middle of the Heading's margin
  top: calc(-#{$headingMarginBottom} / 2);

  @include breakpoint(phablet-up) {
    top: 7px;
    left: 0;
    bottom: 7px;
    width: 3px;
    height: auto;
  }
}

.ui {
  height: $uiHeight;
  position: absolute;
  bottom: -30px;
  @include centerXAbsolute();

  @include breakpoint(phablet-up) {
    right: 0;
    height: auto;
    max-height: 75%;
    max-width: 50%;
    transform: none;
    left: auto;
  }

  @include breakpoint(laptop-up) {
    right: 50px;
  }

  @include breakpoint(desktop) {
    right: 20%;
  }
}
