@import '~styles/_breakpoints';
@import '~styles/_fonts';

.landingClaimCTAContainer {
  padding: 60px 0;
  text-align: center;
}

.landingClaimCTAItem {
  display: inline-block;
  margin: 0 20px;
}

.landingClaimCTAItem p {
  font-size: 24px;
  font-family: $proxima;
}

.landingClaimCTAItem button {
  width: 220px;
  @include breakpoint(laptop-up) {
    width: 350px;
  }
}
