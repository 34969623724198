@import '~styles/_breakpoint-variables';
@import '~styles/_breakpoints';
@import '~styles/_mixins';

$transformationCustomMaxWidth: 795px;

.wrapper {
  position: relative;
  max-width: $transformationCustomMaxWidth;
  margin: 0 auto;

  @include breakpoint(phablet-up) {
    height: 450px;
  }

  @include breakpoint(desktop) {
    height: 500px;
  }
}

.step {
  &:last-of-type > p {
    margin-bottom: 0;
  }

  @include breakpoint(phablet-up) {
    width: calc(100%/3);
    text-align: left;
    position: absolute;

    p {
      margin-bottom: 0;
    }

    &:nth-child(1) {
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      top: 0;
      right: 0;
    }

    &:nth-child(3) {
      bottom: 0;
      right: 0;
    }

    &:nth-child(4) {
      bottom: 0;
      left: 0;
    }
  }

}

.arrow {
  @include breakpoint(phablet-up) {
    position: absolute;

    &:nth-child(1) {
      top: 0;
      left: 50%;
      transform: translateX(-50%) rotate(-90deg);
    }

    &:nth-child(2) {
      top: 50%;
      right: 15%;
      transform: translateY(-50%) rotate(0deg);
    }

    &:nth-child(3) {
      left: 50%;
      bottom: 0;
      transform: translateX(-50%) rotate(90deg);
    }

    &:nth-child(4) {
      top: 50%;
      left: 15%;
      transform: translateY(-50%) rotate(180deg);
    }
  }

  @include breakpoint(tablet-only) {
    img {
      width: 75%;
    }
  }
}

.stepDescription {
  margin-top: 10px;
  margin-bottom: 30px;
}

.circleCrop {
  width: 135px;
  height: 135px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  margin: 40px auto 20px;

  @include breakpoint(phablet-up) {
    height: 180px;
    width: 180px;
    margin: 0;
    position: absolute;
    @include centerBothAbsolute();
  }

  @include breakpoint(desktop) {
    height: 220px;
    width: 220px;
  }

}

.circleCrop > div {
  height: 100%;
  width: 100%;
  position: absolute;
  @include backgroundAttachmentCoverCenter();
}
