@import '_breakpoints';
@import '_colors';
@import '_fonts';

/* CSS Border-box Reset */
:global *,
:global :after,
:global :before {
  box-sizing: border-box;
}

:global html,
:global body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
}

:global body {
  /* Height overrides body is never shorter than viewport */
  min-height: 100%;
  height: auto;
  position: relative;
  font-family: $proxima;
  color: $blackPrimary;
  line-height: 1.6;
  font-size: 14px;
  @include breakpoint(laptop-up) {
    font-size: 16px;
  }
}
