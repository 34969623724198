@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_mixins';

.item {
  text-align: left;
  font-family: $proxima;
  margin: 10px 0;
  p {
    margin: 0;
  }
}

.checkmark {
  display: inline-block;
  height: 27px;
  width: 27px;
  border-radius: 50%;
  background-color: $orangePrimary;
  position: relative;
}

.checkmark:after {
  content: '';
  position: absolute;
  height: 5px;
  width: 10px;
  border: 1px solid white;
  border-top-width: 0;
  border-right-width: 0;
  top: 10px;
  left: 8px;
  transform: rotate(-50deg);
}
