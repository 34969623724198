:global(.ql-indent-1) {
  margin-left: 3em;
}

:global(.ql-indent-2) {
  margin-left: 6em;
}

:global(.ql-indent-3) {
  margin-left: 9em;
}

:global(.ql-indent-4) {
  margin-left: 12em;
}

:global(.ql-indent-5) {
  margin-left: 15em;
}

:global(.ql-indent-6) {
  margin-left: 18em;
}

:global(.ql-indent-7) {
  margin-left: 21em;
}

:global(.ql-indent-8) {
  margin-left: 24em;
}

:global(.ql-align-center) {
  text-align: center;
}

:global(.ql-align-right) {
  text-align: right;
}
