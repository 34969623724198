@import './_breakpoints';

$smallSpacing: 12px;
$mediumSpacing: 20px;
$largeSpacing: 36px;

$gridGutter: $mediumSpacing;
$comboGutter: $smallSpacing;
$comboWideGutter: $largeSpacing;

$globalHeaderHeightMobile: 65px;
$globalHeaderHeight: 80px;

/* Custom sizes for our <Team /> block <Avatar />s */
$teamCustomAvatarMobile: 45px;
$teamCustomAvatarTablet: 105px;
$teamCustomAvatarLaptop: 116px;

@mixin section-padding() {
  padding: 20px 0;
  @include breakpoint(tablet-up) {
    padding: 20px 0;
  }
  @include breakpoint(laptop-up) {
    padding: 20px 0;
  }
  @include breakpoint(mobile-only) {
    padding: 20px 0;
  }
}
