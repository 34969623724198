@import '~styles/_breakpoints';
@import '~styles/_fonts';

.pricingContainer {
  font-family: $proxima;
  /* Reset inherited property from CMS Section */
  text-align: left;
}

/* Cards */
.cardWrapper {
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.cardContainer {
  @include breakpoint (tablet-up) {
    padding: 14px 4px;
  }
  @include breakpoint (laptop-up) {
    padding: 14px;
  }
  p {
    margin-bottom: 0px;
  }
}

.pricingSubText {
  color: black;
  opacity: 0.4;
  font-size: 14px;
  @include breakpoint (tablet-up) {
    font-size: 15px;
  }
  @include breakpoint (laptop-up) {
    font-size: 18px;
  }
}

/* Institution pricing card  */
.institutionPricing {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @include breakpoint (mobile-only) {
    padding: 14px 0;
  }
}

.institutionPricingPrice {
  font-weight: 300;
  line-height: 1.3em;
  font-size: 52px;
  @include breakpoint (tablet-up) {
    font-size: 58px;
    line-height: 1.5em;
  }
  @include breakpoint (laptop-up) {
    font-size: 70px;
  }
}

.institutionalHeading {
  @include breakpoint (mobile-only) {
    text-align: center;
  }
}

.institutionAbout {
  margin-top: 15px;
}

.institutionLink {
  margin-top: 10px;
}

/* Individual pricing card */
.individualPricing {
  text-align: right;
  @include breakpoint (tablet-up) {
    text-align: center;
  }
}

.individualPricingPrice {
  font-weight: 300;
  margin-right: 20px;
  font-size: 28px;
  @include breakpoint (laptop-up) {
    font-size: 34px;
  }
}

.buttonWrapper {
  button {
    height: 45px;
    width: 100%;
    @include breakpoint(tablet-only) {
      height: 38px;
      font-size: 12px;
    }
  }

  margin-left: auto;
  margin-right: 0;

  @include breakpoint (tablet-up) {
    max-width: 157px;
  }
  @include breakpoint (laptop-up) {
    max-width: 224px;
  }
}
