@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_mixins';

.wrapper {
  text-align: center;
  margin-bottom: 40px;
  font-family: $freight;

  @include breakpoint(laptop-up) {
    margin-bottom: 0;
  }

  li {
    @include listReset;
  }
}

.step {
  display: inline-block;
  width: 35px;
  height: 35px;
  position: relative;
  border-radius: 50%;
  .stepNumber {
    position: absolute;
    @include centerBothAbsolute();
    /* Freight's numbers are much shorter than the cap height,
     * so while the span is centered, the text isn't within it.
     */
    top: 45%;
    color: black;
    text-decoration: none;
    outline: none;
  }
}

.active.step {
  background: $navySecondary;
  .stepNumber {
    color: white;
  }
}

/* Pagination.props.proxima */
.proxima {
  font-family: $proxima;
  .stepNumber {
    @include centerBothAbsolute();
  }
}

.previous, .next {
  display: none;
}

.break {
  position: relative;
  display: inline-block;
  width: 18px;
  bottom: 10px;
}
