@import '~styles/_colors';

.chevron {
  display: inline-block;
  transform: rotate(-90deg);
}

.orange svg path {
  stroke: $orangePrimary;
}

.white svg path {
  stroke: white;
}

.grey svg path {
  stroke: $greyTextMed;
}
