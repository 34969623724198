@import '~styles/_borders';
@import '~styles/_colors';
@import '~styles/_shadows';

.accountHeading {
  font-size: 16px;
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 15px;
}

.paid {
  color: $greenSecondary;
}

.orderTable {
  @include border-cards();
  @include shadow-cards();
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
  text-align: left;

  th {
    padding: 14px 20px;
    border-bottom: 1px solid $greyText;
    background-color: $greyBkgLight;
  }

  tr:not(:last-child) td {
    border-bottom: 2px solid transparentize($greyText, 0.7);
  }

  td {
    padding: 20px;
    background-color: white;
  }
}

.downloadButton {
  display: inline;
  color: inherit;
  text-decoration: none;
  font-family: inherit;
  font-weight: 300;
  transition: 0.2s all;
  padding: 0;
  background-color: white;
  font-size: 14px;
  line-height: 16px;
  border: none;
  border-bottom: black 1px solid;
  &:hover {
    cursor: pointer;
  }
  &:hover {
    background: $greyScreen;
  }
}
