@import '~styles/_borders';
@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_mixins';

$innerBorder: 1px solid $greyText;
$outerBorder: 2px solid $greyText;

.wrapper {
  width: 100%;

  /* borders */
  border-collapse: collapse;
  td {
    border: $innerBorder;
  }
  tr:first-child td { border-top: $outerBorder; }
  tr:last-child td { border-bottom: $outerBorder; }
  td:first-child { border-left: $outerBorder; }
  td:last-child { border-right: $outerBorder; }

  /* padding, spacing */
  td {
    padding: 5px;
    @include breakpoint (tablet-up) {
      padding: 5px 20px;
    }

    @include breakpoint (tablet-up) {
      height: 50px;
    }
    @include breakpoint (laptop-up) {
      height: 65px;
    }
  }

  /* text styling */
  td:first-child {
    font-size: 16px;
    font-weight: 400;
    max-width: 50px;
  }
  .total {
    td:first-child {
      font-weight: 700;
    }
    td:last-child {
      @include breakpoint (tablet-up) {
        font-size: 22px;
        font-weight: 300;
      }
    }
  }

  tr:last-child td {
    background-color: $greyBkgLight;
  }
}
