@import '~styles/_breakpoints';
@import '~styles/_colors';

.wrapper {
  background-color: $navyPrimary;
  padding: 75px 0;
  text-align: center;
  @include breakpoint(tablet-up) {
    text-align: left;
  }
}
