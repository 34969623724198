@import '~styles/_borders';
@import '~styles/_colors';
@import '~styles/_fonts';

.wrapper {
  text-align: left;

  :global(.Select) {
    font-size: 14px;
    font-weight: 300;
    font-family: $proxima;
  }

  :global(.Select-control) {
    height: 45px;
    background-color: white;
    @include border-input();
    border-radius: 2px;
    transition: all 0.4s;
    outline: none;
  }

  :global(.Select-loading) {
    margin-right: 10px;
  }

  :global(.Select):global(.is-disabled) > :global(.Select-control) {
    background-color: $greyBkgDark;
  }

  :global(.Select--single) > :global(.Select-control) {
    :global(.Select-value) :global(.Select-value-label),
    :global(.Select-placeholder) {
      color: $greyTextDark;
    }

    :global(.Select-value),
    :global(.Select-placeholder) {
      line-height: 42px;
    }
  }

  :global(.Select-input) > input {
    line-height: 24px;
  }

  :global(.Select-clear) {
    font-size: 24px;
    line-height: 1.5;
  }

  :global(.is-focused) :global(.Select-control) {
    color: darken($greyTextDark, 20%);
    border-color: darken($greyText, 20%);
  }

  $multiBoxBorder: 1px solid $greyText;
  :global(.Select--multi) {
    :global(.Select-multi-value-wrapper) {
      padding-bottom: 5px;
    }
    :global(.Select-value) {
      color: inherit;
      background-color: $greyBkgLight;
      border: $multiBoxBorder;
      line-height: 2em;
    }
    :global(.Select-value-icon) {
      border-right: $multiBoxBorder;
      padding: 2px 5px;
      &:hover {
        background-color: darken($greyBkgLight, 5);
        color: black;
      }
    }
  }
}

:global(.inputHasError) :global(.Select-control) {
  border-color: $redPrimary;
}

.searchIcon {
  margin-top: 8px;
  margin-right: 8px;
  g > * {
    stroke: $greyTextMed;
  }
}
