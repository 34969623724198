@import '~styles/_colors';
@import '~styles/_fonts';

$errorSpanFontSize: 14px;
$errorSpanPadding: 6px;

.errorText {
  font-family: $proxima;
  /* Setting a min-height in the (uncommon) case of wrapping error text */
  min-height: calc(#{$errorSpanPadding} * 2 + #{$errorSpanFontSize});
  span {
    padding-top: $errorSpanPadding;
    font-size: $errorSpanFontSize;
  }
}
