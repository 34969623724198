@import '~styles/_colors';
@import '~styles/_zindex';
@import '_vars';
@import '../../_mixins';

$duration: 300ms;
$timingFunction: ease-in-out;

@mixin transition ($types) {
  $transition: #{$types} $duration $timingFunction;
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

$mobileMenuWidth: 320px;
$closeButtonMinWidth: $mobileMenuWidth + 70px;
$zIndex: $zMobileMenu;

.container {
  @include transition(visibility);
  visibility: hidden;
  content: '';
  position: fixed;
  z-index: $zIndex;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.mobileMenu {
  @include transition(all);
  pointer-events: initial;
  position: absolute;
  overflow: scroll;
  z-index: $zIndex + 1;
  height: 100%;
  max-width: 100%;
  background-color: $navySecondary;
  box-shadow: none;

  left: -$mobileMenuWidth;
  width: $mobileMenuWidth;
  @media (min-width: $tabletMenuWidth + 70px) {
    left: -$tabletMenuWidth;
    width: $tabletMenuWidth;
  }
}

.screen {
  @include transition(background-color);
  background-color: transparent;
  pointer-events: initial;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.closeButton {
  @include buttonReset;
  position: absolute;
  opacity: 0;
  top: 12px;
  right: 16px;
  width: 54px;
  @include transition(opacity);
  path {
    fill: white;
  }

  // if the menu is full width, the close button can't be shown
  display: none;
  @media (min-width: $closeButtonMinWidth) {
    display: block;
  }
}

.closeButtonInline {
  @include buttonReset;
  height: 54px;
  width: 150px;
  margin: 0 auto;
  @include transition(opacity);
  color: white;
  font-size: 16px;

  svg {
    height: 25px;
    display: inline-block;
    padding-top: 0px;
    margin-bottom: -7px;
    path {
      fill: white;
    }
  }

  display: none;
  @media (max-width: $closeButtonMinWidth - 1) {
    display: block;
  }
}

.isOpen {
  visibility: visible;
  .mobileMenu {
    left: 0;
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.3);
  }
  .screen {
    background-color: $boxShadowMenu;
  }
  .closeButton {
    opacity: 1;
  }
}

.mobileMenuHeader:not(:empty) {
  padding: 25px 36px;
}

.joinButton {
  display: block;
}

.logo {
  height: 44px;
}

.logInButton, .logOutButton {
  cursor: pointer;
}
