@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_mixins';
@import '../../../_mixins';

.myAccountMenu {
  overflow: visible;
  margin-left: 5px;

  /**
   * !important because the parent element is setting
   * display: block; and that's taking priority
   */
  display: none !important;
  @include breakpoint (laptop-up) {
    display: inline-block !important;
  }

  > *:not(.myAccountSubmenu) {
    line-height: 30px;
    vertical-align: middle;
    margin: 0 5px;
    opacity: 0.7;
    font-weight: 400;
    transition: 0.2s opacity;
  }

  $menuWidth: 184px;

  @include dropdownMenuButton(myAccountSubmenu);

  .myAccountSubmenu {
    @include dropdownMenuWrapperAndMargin($menuWidth, -12px);
  }

  ul {
    @include dropdownMenuContentAccount(30px);

    $menuPadding: 24px;
    text-align: left;
    padding: $menuPadding;
    font-size: 14px;
    font-weight: 500;

    li {
      @include linkHover();
      $padding: 6px 0;
      > * {
        display: block;
        padding: $padding;
      }
      button {
        @include buttonReset();
        padding: $padding;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
