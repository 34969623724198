@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_mixins';
@import '~styles/_spacing';

.wrapper {
  display: inline-block;
  font-family: $proxima;
  border-radius: 50%;
  color: white;
  font-size: 15px;
  position: relative;
  overflow: hidden;
}

.wrapper.bgFill {
  background-color: $blackPrimary;
  &.lightFill {
    color: $greyDark;
    background-color: $greyText;
  }
}

.avatar {
  position: absolute;
  height: 100%;
  width: 100%;
  @include backgroundAttachmentCoverCenter();
}

.letter {
  position: absolute;
  @include centerBothAbsolute();
  /* Some optical alignment finesse 😉 */
  padding-top: 1px;
}

/* Avatar.props.size */
.xs {
  width: 28px;
  height: 28px;
}

.sm {
  width: 32px;
  height: 32px;
}

.md {
  width: 40px;
  height: 40px;
  font-size: 18px;
}

.lg {
  width: 36px;
  height: 36px;
  font-size: 20px;
  @include breakpoint(laptop-up) {
    width: 55px;
    height: 55px;
    font-size: 28px;
  }
}

.lg-fixed {
  width: 55px;
  height: 55px;
  font-size: 28px;
}

.xl {
  width: 76px;
  height: 76px;
}

.xxl {
  width: 166px;
  height: 166px;
}

.avatarUpload {
  width: 150px;
  height: 150px;
  font-size: 36px;
}

/* A custom size specifically for our Team page-block */
.teamCustom {
  @extend .lg;
  width: $teamCustomAvatarMobile;
  height: $teamCustomAvatarMobile;

  @include breakpoint(tablet-up) {
    height: $teamCustomAvatarTablet;
    width: $teamCustomAvatarTablet;
  }

  @include breakpoint(laptop-up) {
    width: $teamCustomAvatarLaptop;
    height: $teamCustomAvatarLaptop;
  }
}
