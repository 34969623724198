@import '~styles/_breakpoints';
@import '~styles/_spacing';

.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -$gridGutter;
}

.grid > * {
  padding-left: $gridGutter;
}

.grid.middle {
  justify-content: center;
}

.grid.center {
  align-items: center;
}

.grid.stretch {
  align-items: stretch;
}

.grid.noGutter {
  margin-left: 0;
}

.grid.noGutter > * {
  padding-left: 0;
}

.grid.rowReverse {
  flex-direction: row-reverse;
}

@include breakpoint(tablet-only) {
  .tablet1of1 {
    width: 100% !important;
  }

  .tablet1of2 {
    width: 50% !important;
  }

  .tablet1of3 {
    width: 33% !important;
  }

  .tablet2of3 {
    width: 67% !important;
  }

  .tabletHide {
    display: none;
  }
}

@include breakpoint(phablet-only) {
  .phablet1of1 {
    width: 100% !important;
  }

  .phablet1of2 {
    width: 50% !important;
  }

  .phablet1of3 {
    width: 33% !important;
  }

  .phablet2of3 {
    width: 67% !important;
  }

  .phabletHide {
    display: none;
  }
}

@include breakpoint(mobile-only) {
  .mobile1of1 {
    width: 100% !important;
  }

  .mobile1of2 {
    width: 50% !important;
  }

  .mobile1of3 {
    width: 33% !important;
  }

  .mobile2of3 {
    width: 67% !important;
  }

  .mobileHide {
    display: none;
  }
}



