@import '~styles/_borders';
@import '~styles/_breakpoints';
@import '~styles/_breakpoint-variables';
@import '~styles/_colors';
@import '~styles/_spacing';

$narrow: 740px;

.learning,
.featured,
.forward,
.events,
.curriculum,
.news {
  @include section-padding();
  @include border-dashboard(bottom);
}

.sectionWrapper {
  margin: 0 auto;
  max-width: $desktop-min;
  position: relative;

  @include breakpoint(mobile-only) {
    padding: 0 $padding-mobile;
  }

  @include breakpoint(tablet-only) {
    padding: 0 $padding-tablet;
  }

  @include breakpoint(laptop-only) {
    padding: 0 $padding-laptop;
  }

  @include breakpoint(desktop) {
    padding: 0 $padding-desktop;
  }
}

.sectionOverride {
  @include breakpoint(mobile-only) {
    padding: 0 $padding-mobile;
  }
}

/* Section.props.mobileNoPadding */
.mobileNoPadding {
   @include breakpoint(mobile-only) {
     padding: 0;
   }
}

/* Section.props.tabletNoPadding */
.tabletNoPadding {
  @include breakpoint(tablet-only) {
    padding: 0;
  }
}

/* Section.props.narrow */
.narrow {
  max-width: $narrow;
}