@import '~styles/_breakpoints';
@import '~styles/_colors';

.header {
  text-align: center;
  padding: 24px 0 12px;
  @include breakpoint(laptop-up) {
    padding: 38px 0 26px;
  }
}

.logo {
  max-height: 45px;
}
