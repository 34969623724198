@import '~styles/_borders';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_mixins';

$height: 38px;

.discountCodeInput {
  background-color: white;
  @include border-input();
  border-radius: 2px;
  height: $height;
  width: 100%;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 300;
  color: $greyTextDark;
  transition: all 0.4s;
  margin: 10px 0 10px !important;

  &:focus {
    outline-width: 0;
    color: darken($greyTextDark, 20%);
    border-color: darken($greyText, 20%);
  }

  &[disabled] {
    background-color: $greyBkgDark;
  }
}

.discountCodeButton {
  position: relative;
  width: 100%;
  height: $height;
  font-family: $proxima;
  font-weight: 500;
  color: white;
  font-size: 13px;
  border-width: 2px;
  border-style: solid;
  transition: 0.2s all;
  padding: 0;

  background-color: $orangePrimary;
  border-color: $orangePrimary;
  &:hover {
    cursor: pointer;
    background-color: $orangePrimaryHover;
  }
  &:active {
    background-color: $orangePrimaryActive;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .spinner {
    position: absolute;
    @include centerYAbsolute();
    right: 2px;
    height: 63%;
  }
}

.discountCodeError {
  font-family: $proxima;
  font-size: 14px;
  margin: 0.2em 0 0.6em;
  height: 1em;
}
