@import '~styles/_colors';

.notice {
  color: $greyDark;
  background-color: $greyBkgDark;
  padding: 14px 20px 16px;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 27px;
}
