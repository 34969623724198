@import '~styles/_colors';
@import '~styles/_shadows';
@import '../_common';

$padding: 30px;
$border: 1px solid $greyScreen;

.metricsSubTitle {
  font-size: 13px;
  font-weight: 300;
  margin-top: -15px;
  margin-bottom: 0;
}

.metricsNumber {
  font-size: 40px;
  font-weight: 400;
  margin-top: -25px;

  @include large-screen {
    margin: 0;
    width: 100%;
    position: absolute;
    bottom: 54px;
  }
}

.metricsCard {
  background-color: white;
  border: 1px solid $greyText;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  @include shadow-cards();
}

.metricsSubcards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  &:not(:last-child) {
    border-bottom: $border;
  }
}

.metricsSubcard {
  position: relative;
  text-align: center;

  &.metricsSubcardGrey {
    padding: $padding;
    background-color: $greyBkgLight;
  }

  @include small-screen {
    // padding: 22px $padding;
    width: 100%;
    &:not(:last-child) {
      border-bottom: $border;
    }
  }

  @include large-screen {
    &.half {
      width: 50%;
      .metricsNumber {
        bottom: 22px;
      }
    }

    &.third {
      width: 33.3333%;
    }

    min-height: 200px;
    &:not(:last-child) {
      border-right: $border;
    }
  }
}

.metricsTitle {
  font-size: 18px;
  padding: 0;
  margin: 0 0 20px;
}

.chartTooltip {
  background-color: white;
  border: 1px solid $greyText;
  padding: 5px;
  border-radius: 1px;
  @include shadow-cards();
}

.title {
  padding: 15px;
  @include large-screen {
    min-height: 85px;
    background-color: $greyBkgLight;
    padding: $padding $padding 0;
    border-bottom: $border;
    &.withSubtitle {
      padding: 15px;
    }
  }
}