@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';

$detailsBorder: 1px solid $greyText;
$detailsHeight: 380px;
$detailsMarginTB: 30px;
$detailsMarginLR: 45px;

.wrapper {
  background-color: $greyBkgDark;
  padding: 30px 0;
  @include breakpoint(tablet-up) {
    padding: 42px 0 30px;
  }
  @include breakpoint(laptop-up) {
    padding: 80px 0;
  }
  /* Treating <p>s differently in this context */
  p {
    margin: 0;
  }
}

.details {
  background: white;
  display: flex;
  flex-direction: column;
  border: $detailsBorder;
  
  @include breakpoint(laptop-up) {
    flex-direction: row;
    height: $detailsHeight;
  }

  .mobileVideoHeader {
    display: flex;
    @include breakpoint(laptop-up) {
      display: none;
    }

    padding: 18px 12px;
    justify-content: space-between;
    gap: 1rem;
    
    .mobileCTA {
      width: 33%;
    }
  }

  .mobileVideoFooter {
    display: flex;
    @include breakpoint(laptop-up) {
      display: none;
    }

    .mobileVideoFooterSection {
      flex: 1;

      &:first-child {
        border-right: $detailsBorder
      }
      &:last-child {
        border-right: none;
      }
      padding: 20px;
    }
  }
}

.rightDetailSection {
  flex-direction: column;
  padding: 30px 45px;
  gap: 1rem;
  overflow: scroll;
  flex: 3;
  display: none;

  @include breakpoint(laptop-up) {
    display: flex;
  }
}




.video {
  background: black;
  width: 100%;
  /*
   * https://developer.mozilla.org/en/docs/Web/CSS/padding-top
   * When passing percentage-based values to padding-top -- reference is taken
   * from the element width. Using that, we can always maintain an exact ratio
   * to our element width. Here, we're looking for 9:16 aspect ratio to maintain.
   */
  padding-top: calc(9 / 16 * 100%);
  position: relative;

  iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @include breakpoint(laptop-up) {
    display: inline-block;
    padding-top: 0;
    height: $detailsHeight;
    flex: 6;
  }
}

.footerWrapper {
  @include breakpoint(tablet-only) {
    display: table;
    background: white;
    width: 100%;
    .detailWrapper {
      display: table-cell;
      width: 50%;
      padding: 20px;
      vertical-align: middle;
      min-height: 90px;
      &:last-child {
        border-left: none;
      }
    }
  }
}

.date {
  font-family: $proxima;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 600;

  @include breakpoint(tablet-up) {
    font-size: 20px;
  }

  @include breakpoint(laptop-up) {
    font-size: 24px;
  }
}

.contactUs,
.time {
  font-family: $proxima;
  font-size: 14px;

  @include breakpoint(tablet-up) {
    font-size: 16px;
  }
}

.contactUs {
  font-weight: 700;
}

.email {
  font-weight: 400;
  font-size: 14px;
  font-style: italic;

  @include breakpoint(tablet-up) {
    font-size: 16px;
  }
}

.contactUs,
.email {
  line-height: 1.4;
}

.button {
  @include breakpoint(laptop-up) {
    display: block;
    margin: 12px 0;
  }
}

.public h4 {
  text-align: left;
  @include breakpoint (laptop-up) {
    text-align: center;
    margin-top: 30px;
  }
}

.public p {
  padding-right: 20px;
  @include breakpoint (laptop-up) {
    padding-right: 0;
  }
}

.public .button {
  margin-top: 30px;
  margin-bottom: 0;
}

/* Safe-check in case facilitator user account gets deleted */
 .contactSpacer {
   @include breakpoint(laptop-up) {
    height: 55px;
   }
 }
