/* Black Values */
$blackHeading: rgba(0, 0, 0, 1.00);
$blackPrimary: rgba(46, 46, 46, 1.00);

/* Grey Values  */
$greyText: rgba(222, 222, 222, 1.00);
$greyTextMed: rgba(171, 171, 171, 1.00);
$greyTextDark: rgba(131, 131, 131, 1.00);
$greyBkgDark: rgba(243, 243, 243, 1.00);
$greyBkgLight: rgba(250, 250, 250, 1.00);
$greyDark: rgba(71, 71, 71, 1.00);
$greyDarkHover: lighten($greyDark, 5%);
$greyDarkActive: darken($greyDark, 5%);

/* Green Values */
$greenPrimary: rgba(61, 194, 128, 1.00);
$greenPrimaryHover: lighten($greenPrimary, 5%);
$greenPrimaryActive: darken($greenPrimary, 5%);
$greenPrimaryScreen: transparentize($greenPrimary, 0.8);
$greenSecondary: rgba(52, 195, 125, 1.00);

/* Orange Values */
$orangePrimary: rgba(230, 147, 66, 1.00);
$orangePrimaryHover: lighten($orangePrimary, 5%);
$orangePrimaryActive: darken($orangePrimary, 5%);

/* Red Values */
$redPrimary: rgba(253, 64, 70, 1.00);
$redPrimaryHover: lighten($redPrimary, 5%);
$redPrimaryActive: darken($redPrimary, 5%);

/* Blue Values */
$bluePrimary: rgba(56, 65, 184, 1.00);
$bluePrimaryHover: lighten($bluePrimary, 5%);
$bluePrimaryActive: darken($bluePrimary, 5%);
$navyPrimary: rgba(13, 23, 87, 1);
$navyPrimaryHover: lighten($navyPrimary, 5%);
$navyPrimaryActive: darken($navyPrimary, 5%);
$navySecondary: rgba(20, 28, 110, 1);
$navySecondaryHover: lighten($navySecondary, 5%);
$navySecondaryActive: darken($navySecondary, 5%);
$lightBlue: rgba(56, 60, 188, 1);
$purplePrimary: rgba(28, 33, 129, 1.00);

/* Screen Values */
$greyScreen: rgba(200, 200, 200, 0.3);
$whiteScreen: rgba(255,255,255,0.3);
$boxShadowMenu: rgba(0, 0, 0, 0.3);
$heroScreen: transparentize($navyPrimary, 0.30);

@mixin gradient-page-with-sidebar() {
  background: linear-gradient(to right,  #ffffff 0%,#ffffff 70%,#fafafa 70%,#fafafa 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin gradient-white() {
  background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0.7) 65%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin gradient-navy() {
  background: linear-gradient(to bottom, rgba(13, 23, 87,0.7) 0%,rgba(13, 23, 87,0.6) 20%,rgba(13, 23, 87,0.4) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin gradient-content-grid() {
  background: linear-gradient(to bottom, rgba(122,126,163,1) 0%,rgba(90,94,147,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin gradient-blue-card() {
  @include gradient-content-grid();
}
