@import '~styles/_colors';
@import '~styles/_mixins';
@import '~styles/_zindex';

.hero {
  @include backgroundAttachmentCoverCenter();
  padding: 60px;
  color: white;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include gradient-navy();
  }
  * {
    position: relative;
    z-index: $zResetContentAbovePseudoMask;
  }
}
/*
 * Heading + Link have to be wrapped cause the Grid expects direct
 * children of GridItems
 */
.heading {
  margin-bottom: 60px;
}
