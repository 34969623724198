/* Styles specific for <CardCollection /> */
@import '~styles/_mixins';

.countCircle {
  font-family: $proxima;
  color: white;
  background: $bluePrimary;
  height: 54px;
  width: 54px;
  position: relative;
  border-radius: 50%;
  > * {
    position: absolute;
    @include centerBothAbsolute();
  }
}

.countCircleComplete {
  background: $greenPrimary;
}

.headingCollection {
  min-height: 65px;
  @include breakpoint(tablet-only) {
    min-height: 80px;
  }
}

.watchButton {
  max-width: 170px;
}
