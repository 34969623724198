@import '~styles/_fonts';

.nearlyThere {
  font-family: $proxima;
  font-size: 19px;
  margin: 10px 0;
}

.nearlyThereText {
  font-family: $freight;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.62;
  margin: 10px 0;
}
