@import '~styles/_borders';
@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_spacing';

.webinarDate,
.webinarTime,
.webinarType {
  margin: 0;
  font-size: 14px;
  font-family: $proxima;
}

.webinarDate,
.webinarTime {
  display: inline-block;
  color: $greyTextDark;
  font-size: 14px;
  margin-bottom: 10px;
  &:last-child {
    margin-left: 8px;
  }

  @include breakpoint(tablet-only) {
    font-size: 16px;
  }

  @include breakpoint(laptop-up) {
    display: block;
    margin-bottom: 0;
    &:last-child {
      margin-left: 0;
    }
  }
}

.webinarDate,
.webinarType {
  @include breakpoint(tablet-up) {
    font-size: 16px;
    color: $blackPrimary;
  }
}

.webinarRegister {
  padding: $padding-mobile;
  margin: -#{$padding-mobile};
  margin-top: $padding-mobile;
  @include border-dashboard(top);

  @include breakpoint(tablet-only) {
    padding: $padding-tablet;
    margin: -#{$padding-tablet};
    margin-top: $padding-tablet;
  }

  @include breakpoint(laptop-up) {
    padding: 0;
    margin: 0;
    border-top: none;
  }
}
