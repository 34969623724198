@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_shadows';
@import '../_common';

.chartWrapper {
  width: 100%;
  text-align: center;
  padding-top: 20px;
  @media (max-width: 536px) {
    display: none;
  }
}

.chart {
  width: 480px;
  margin: -20px auto 0;
}
