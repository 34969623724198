@import '~styles/_breakpoints';
@import '~styles/_fonts';

.headerContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 0px;
  margin: 0 auto;

  @include breakpoint (laptop-up) {
    padding: 90px 0px;
  }

  @include breakpoint (tablet-up) {
    flex-direction: row;
    justify-content: center;
    &.headerContainerWithForm {
      justify-content: space-between;
    }
  }
}



.headerContent {
  @include breakpoint (tablet-up) {
    text-align: center;
    max-width: 400px;
  }
  @include breakpoint (laptop-up) {
    max-width: 590px;
  }
}

.buttonContainer {
  @include breakpoint (tablet-up) {
    max-width: 400px;
    margin: 0 auto;
  }
}

.saleStateText {
  font-size: 16px;
  font-family: $proxima;
  @include breakpoint (tablet-up) {
    text-align: center;
  }
}
