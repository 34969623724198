/* A (safe) space for mixins w/o a specific home */
@mixin centerYAbsolute () {
  top: 50%;
  transform: translateY(-50%);
}

@mixin centerXAbsolute () {
  left: 50%;
  transform: translateX(-50%);
}

@mixin centerBothAbsolute () {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin backgroundAttachmentCover () {
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin backgroundAttachmentCoverCenter () {
  @include backgroundAttachmentCover();
  background-position: 50% 50%;
}

@mixin listReset () {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
