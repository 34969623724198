@import '~styles/_borders';
@import '~styles/_mixins';
@import '~styles/_shadows';

.navigation {
  @include listReset();

  font-size: 14px;
  background-color: white;
  border: $borderCard;
  @include shadow-cards;
  margin-bottom: 20px;

  li {
    > * {
      display: block;
      padding: 16px 28px;
    }

    &:not(:last-child) {
      border-bottom: $borderCard;
    }
  }

  .active {
    font-weight: 600;
  }
}
