@import '~styles/_breakpoint-variables';
@import '~styles/_breakpoints';
@import '~styles/_fonts';

.wrapper {
  font-family: $proxima;
}

.logoWrapper {
  width: 50%;
  display: inline-block;
  opacity: 0.5;
  height: 30px;
  margin: 0 auto 25px;

  @include breakpoint(phablet-up) {
    width: 25%;
    margin-bottom: 40px;
  }

  @include breakpoint(laptop-up) {
    height: 40px;
  }
}

.logoWrapper img {
  height: 100%;
}

.copy {
  width: 250px;
  margin: 0 auto;
  @include breakpoint(tablet-up) {
    width: 100%;
  }
}

.copy p {
  margin: 0;
  @include breakpoint(phablet-up) {
    margin-right: 5px;
    display: inline-block;
  }
}
