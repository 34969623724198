.explainer {
  margin-bottom: 20px;
  font-size: 14px;
}

.secondaryExplainer {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 14px;
}

.buddyHeading {
  margin: 20px 0;
  font-weight: 600;
  font-size: 14px;
}
