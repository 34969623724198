@import '~styles/_borders';
@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_mixins';

.reviewHeader {
  padding: 0;
  border: none;
  width: 100%;
  height: 100%;
  text-align: left;
}

.reviewHeading {
  display: inline-block;
}

.reviewEditLink {
  font-size: 14px;
  position: relative;
  bottom: 1px;
  border-bottom: none !important;
}

.checkmark {
  display: inline-block;
  height: 20px;
  width: 20px;
  @include breakpoint(phablet-up) {
    height: 21px;
    width: 21px;
  }
  border-radius: 50%;
  background-color: $greenPrimary;
  position: relative;
}

.checkmark:after {
  content: '';
  position: absolute;
  border: 1px solid white;
  border-top-width: 0;
  border-right-width: 0;
  height: 4px;
  width: 9px;
  top: 7px;
  left: 6px;
  @include breakpoint(phablet-up) {
    top: 8px;
  }
  transform: rotate(-50deg);
}

.checklistItem {
  text-align: left;
  font-family: $proxima;
  padding: 8px 0;
  p {
    margin: 0;
  }
}

.envelopeImage {
  padding-left: 10px ;
  width: 85px;
  @include breakpoint(laptop-up) {
    padding-left: 0;
    width: auto;
  }
}

.stayInformedFlexContainer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
  @include breakpoint(laptop-up) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.stayInformedText {
  font-size: 20px;
  line-height: 1.4;
  font-weight: 500;
  @include breakpoint(laptop-up) {
    font-size: 23px;
    font-weight: normal;
    width: 290px;
  }
}

.checklistPreText {
  padding:0 0 15px 0;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: none;
  @include breakpoint(phablet-up) {
    display: block;
  }
}

.checklistText {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.subscribeText {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.5;
  letter-spacing: normal;

  // css for custom checkbox
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.subscribeText input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.subscribeText span {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  margin: 0 12px 7px 0;
  padding: 5px 4px;
  border-radius: 15%;
  border: solid 1.5px #cfcfcf;
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.subscribeText input:checked ~ span {
  background-color: #131770;
  border-color: #131770
}

/* Create the checkmark/indicator (hidden when not checked) */
.subscribeText span:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.subscribeText input:checked ~ span:after {
  display: block;
}

/* Style the checkmark/indicator */
.subscribeText span:after {
  content: '';
  position: absolute;
  border: 1px solid white;
  border-top-width: 0;
  border-right-width: 0;
  height: 4px;
  width: 9px;
  top: 3px;
  left: 2px;
  transform: rotate(-50deg);
}

.reviewToggle {
  padding: 5px;
  background: none;
  border: none;
  float: right;
  margin-top: 2px;
  &:hover {
    cursor: pointer;
    color: lighten($blackPrimary, 10);
    transition: color 0.2s;
  }
  &:focus { outline: none; }
}

.reviewToggleIcon {
  width: 25px;
  height: 25px;
}

.reviewTable {
  @include breakpoint(tablet-up) {
    tr:not(:last-child) td {
      padding-bottom: 16px;
    }
  }

  td:first-child {
    padding-right: 10px;
    @include breakpoint(tablet-up) {
      min-width: 100px;
    }
    @include breakpoint(laptop-up) {
      min-width: 160px;
    }
  }

  td ul {
    padding-left: 18px;
    margin: 0;
  }

  font-size: 16px;
  font-weight: 300;

  .reviewSyncError {
    color: $redPrimary;
  }
}
