.radio {
  height: 45px;
  line-height: 45px;
}

.horizontal {
  .radioOption {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.vertical {
  .radioOption {
    display: block;
  }
}
