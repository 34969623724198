@import '~styles/_breakpoints';

.video {
  background: black;
  width: 100%;
  /*
   * https://developer.mozilla.org/en/docs/Web/CSS/padding-top
   * When passing percentage-based values to padding-top -- reference is taken
   * from the element width. Using that, we can always maintain an exact ratio
   * to our element width. Here, we're looking for 9:16 aspect ratio to maintain.
   */
  padding-top: calc(9 / 16 * 100%);
  position: relative;

  iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
