@import '~styles/_breakpoints';
@import '~styles/_fonts';

.wrapper {
  @include breakpoint(laptop-up) {
    padding: 12px;
  }
}

.copy {
  font-family: $proxima;

  @include breakpoint(tablet-up) {
    text-align: left;
    padding-right: 20px;
  }
  @include breakpoint(laptop-up) {
    padding-right: 30px;
  }
}

.button {
  margin-top: 20px;

  @include breakpoint(phablet-up) {
    margin-top: 0;
  }

  @include breakpoint(laptop-up) {
    padding-left: 30px;
  }
}
