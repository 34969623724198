@import '~styles/_breakpoints';

.wrapper {
  width: 100%;
}

.joined, .expires {
  text-align: center;
  @include breakpoint(tablet-up) {
    text-align: left;
    display: inline-block;
  }
}

.expires {
  @include breakpoint(tablet-up) {
    float: right;
    text-align: right;
  }
}

/* Progress bar */

.progressBar {
  width: 100%;
  height: 30px;
  margin-top: 22px;
  border: none;
  background: rgba(216, 216, 216, 0.4);
  -webkit-appearance: none;
  -moz-appearence: none;
}

.progressBar::-webkit-progress-bar {
  background: rgba(216, 216, 216, 0.4);
}

.progressBar::-webkit-progress-value,
 {
  background-image: linear-gradient(to bottom, #232896, #131770);
}

.progressBar[value]::-moz-progress-bar {
  background-image: linear-gradient(to bottom, #232896, #131770);
 }
