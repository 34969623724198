@import '~styles/_breakpoint-variables';
@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_mixins';

@import '_tabs';

.page {
  @include breakpoint (laptop-up) {
    @include gradient-page-with-sidebar();
    display: table;
    margin: 0 auto;
    width: 100%;
    .main,
    .sidebar {
      display: table-cell;
      vertical-align: top;
    }

    .main {
      width: 100%;
    }

    .sidebar {
      width: 0;
    }

    .tableOverride {
      width: 355px;
    }
  }
  @include breakpoint(desktop) {
    .tableOverride {
      width: 410px;
    }
  }
}

.main {
  padding: 20px 0;
  @include breakpoint (tablet-up) {
    padding-bottom: 60px;
  }
}

.sidebar {
  background: $greyBkgLight;
  padding-top: 34px;
  padding-bottom: 40px;
  position: relative;

  @include breakpoint (tablet-only) {
    margin-left: -#{$padding-tablet};
    margin-right: -#{$padding-tablet};
    padding-left: $padding-tablet;
    padding-right: $padding-tablet;
  }

  @include breakpoint(laptop-up) {
    padding-left: 40px;
  }
}
