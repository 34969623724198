@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_mixins';

$mobileSize: 160px;
$laptopSize: 220px;

.pieContainer {
  margin: 30px auto 20px;
  height: $mobileSize;
  width: $mobileSize;
  border-radius: 50%;
  background-image: linear-gradient(to right, transparent 50%, white 0);
  /* Hides any breaking elements */
  overflow: hidden;
  position: relative;
  box-shadow: 0 0px 25px rgba(0,0,0,0.1);

  @include breakpoint(laptop-up) {
    height: $laptopSize;
    width: $laptopSize;
  }
}

.pieHalf {
  margin-left: 50%;
  height: 100%;
  transform-origin: left;
}

.center {
  background: white;
  border-radius: 50%;
  position: absolute;
  @include centerBothAbsolute();
  /* Create a 5px "stroke" effect" */
  height: calc(#{$mobileSize} - 10px);
  width: calc(#{$mobileSize} - 10px);

  @include breakpoint(laptop-up) {
    /* Create a 7px "stroke" effect" */
    height: calc(#{$laptopSize} - 14px);
    width: calc(#{$laptopSize} - 14px);
  }
}

.value {
  font-family: $proxima;
  font-size: 35px;
  position: absolute;
  @include centerBothAbsolute();
  @include breakpoint(laptop-up) {
    font-size: 45px;
  }
}
