@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';

.cardWrapper {
  margin-bottom: 30px;
  @include breakpoint(laptop-up) {
    margin-bottom: 0;
  }
}

.dashboard {
  padding: 30px 50px;
  max-height: 330px;
  height: 330px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @include breakpoint(laptop-up) {
    padding: 10px 30px;
  }
}

.details,
.detailsWhite {
  font-family: $proxima;
  margin: 0;
  font-size: 16px;
  text-align: center;
}

/* Used in the "Learning" section of Dashboard */
.countFraction {
  color: $bluePrimary;
  font-family: $proxima;
  font-size: 18px;
  padding: 12px 20px 10px 20px;
  border: 2px solid $bluePrimary;
  border-radius: 36px;
}

.details {
  color: $greyTextDark;
}

.headingBootcamp {
  margin-top: 30px;
}
