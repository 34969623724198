@import '~styles/_breakpoints';

.wrapper {
  text-align: center;
  img {
    width: 100px;
    @include breakpoint (tablet-up) {
      width: 150px;
    }
  }
}
