@import '~styles/_borders';
@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_mixins';

.wrapper {
  @include border-input();
  display: table;

  text-align: center;
  width: 100%;
  padding: 5px;
  @include breakpoint (tablet-up) {
    overflow: hidden;
    text-align: left;
    width: 400px;
    padding: 0;
  }
  @include breakpoint (laptop-up) {
    width: 100%;
  }
}

.wrapper,
.title,
.price {
  @include breakpoint (tablet-up) {
    height: 50px;
  }
  @include breakpoint (laptop-up) {
    height: 65px;
  }
}

.title,
.price {
  padding: 0 20px;
  display: block;
  @include breakpoint (tablet-up) {
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;
  }
}

.title {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  @include breakpoint (tablet-up) {
    background-color: $greyBkgLight;
  }
  @include breakpoint (laptop-up) {
    min-width: 200px;
  }
}

.price {
  font-size: 20px;
  @include breakpoint (tablet-up) {
    width: 100%;
    font-size: 18px;
  }
  @include breakpoint (laptop-up) {
    border-left: 2px solid $greyText;
    font-size: 24px;
  }
}

.navy {
  &.wrapper {
    border-width: 1px;
    border-color: $navySecondary;
  }
  .price { border-left: none; }
  @include breakpoint (tablet-up) {
    .title {
      color: white;
      background-color: $navySecondary;
    }
  }
}
