@import '~styles/_colors';
@import '~styles/_breakpoints';
@import '~styles/_fonts';

.heading {
  font-family: $proxima;
  color: inherit;
  line-height: 1.4;
  margin-top: 0;
}

h1,
h2,
h3 {
  font-weight: 300;
}

h4,
h5,
h6 {
  font-weight: 600;
}

h1 {
  font-size: 30px;
  @include breakpoint(tablet-up) {
    font-size: 34px;
  }
  @include breakpoint(laptop-up) {
    font-size: 42px;
  }
}

h2 {
  font-size: 26px;
  margin-bottom: 16px;
  @include breakpoint(tablet-up) {
    font-size: 30px;
  }
  @include breakpoint(laptop-up) {
    font-size: 36px;
    margin-bottom: 20px;
  }
}

h3 {
  font-size: 24px;
  @include breakpoint(tablet-up) {
    font-size: 30px;
  }
}

h4 {
  font-size: 20px;
  @include breakpoint(laptop-up) {
    font-size: 24px;
  }
}

h5 {
  font-size: 18px;
  @include breakpoint(laptop-up) {
    font-size: 20px;
  }
}

h6 {
  font-size: 14px;
  @include breakpoint(tablet-up) {
    font-size: 18px;
  }
}

/* Heading.propTypes.freight */
.freight {
  font-family: $freight;
}

/* Heading.propTypes.weight */
.light {
  font-weight: 300;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

/* Heading.propTypes.noMargin */
.noMargin {
  margin-bottom: 0;
}

/* Heading.propTypes.center */
.center {
  text-align: center;
}

/* Heading.propTypes.black */
.black {
  color: $blackHeading;
}

.white {
  color: white;
}

/* Heading.propTypes.underline */
.underline {
  text-decoration: underline;
}
