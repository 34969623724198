@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_mixins';

.participantCount {
  text-align: center;
  font-weight: 500;
  margin-bottom: 0;
}

.reservationList {
  @include listReset;
  height: 250px;
  @include breakpoint (laptop-up) {
    height: 350px;
  }
  overflow: scroll;
  max-width: 589px;
  margin: 10px auto 0;
}

.reservationListItem {
  position: relative;
  height: 60px;
  width: 100%;
  display: table;
  &:not(:last-child) {
    border-bottom: 1px solid $greyText;
  }
}

.cell {
  display: table-cell;
  vertical-align: middle;

  &.avatar {
    width: 50px;
    padding-left: 6px;
    padding-top: 8px;
  }

  &.name {
    font-weight: 400;
  }

  &.removeButtonWrapper {
    position: absolute;
    right: 8px;
    padding-top: 18px;
  }
}

.removeButton {
  background-color: $greyBkgDark;
  color: $greyTextMed;
  $radius: 12px;
  height: $radius * 2;
  width: $radius * 2;
  border-radius: $radius;
  border: none;
  font-size: 18px;
  line-height: 1em;
  cursor: pointer;
}

.email {
  color: $greyTextMed;
}