@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_mixins';

.button {
  position: relative;
  width: 100%;
  font-family: $proxima;
  font-weight: 500;
  color: white;
  font-size: 20px;
  border-width: 2px;
  border-style: solid;
  margin-bottom: 24px;
  transition: 0.2s all;
  // Override to ensure text rendering vertically aligned
  padding: 0;
  &:hover {
    cursor: pointer;
  }
}

/* Button.props.size */
.primary {
  min-height: 45px;

  @include breakpoint(tablet-up) {
    min-height: 50px;
  }
  @include breakpoint(laptop-up) {
    min-height: 65px;
  }
}

.secondary {
  min-height: 45px;

  @include breakpoint(tablet-only) {
    min-height: 40px;
  }
}

.tertiary {
  height: 30px;
  font-size: 14px;

  @include breakpoint(laptop-up) {
    min-height: 35px;
  }
}

/* Button.props.color */
.orange {
  background-color: $orangePrimary;
  border-color: $orangePrimary;
  &:hover {
    background-color: $orangePrimaryHover;
    border-color: $orangePrimaryHover;
  }
  &:active {
    background-color: $orangePrimaryActive;
    border-color: $orangePrimaryActive;
  }
}

.green {
  background-color: $greenPrimary;
  border-color: $greenPrimary;
  &:hover {
    background-color: $greenPrimaryHover;
    border-color: $greenPrimaryHover;
  }
  &:active {
    background-color: $greenPrimaryActive;
    border-color: $greenPrimaryActive;
  }
}

.white {
  background-color: transparent;
  border-color: white;
  &:hover {
    background-color: $greyScreen;
  }
}

/* Button.propTypes.outline */
.secondary.orange.outline,
.tertiary.orange {
  background-color: transparent;
  color: $orangePrimary;
  font-size: 14px;
  &:hover {
    background-color: transparent;
  }
}

.outline {
  background-color: transparent;
  color: $orangePrimary;
  &:hover {
    background-color: transparent;

  }
}

/* Button.propTypes.noMargin */
.noMargin {
  margin: 0;
}

.button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.button[disabled].success {
  opacity: 1;
}

.spinner {
  position: absolute;
  @include centerYAbsolute();
  right: 10px;
  height: 50%;
}
