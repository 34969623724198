@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_mixins';

$contentGridCustomMaxWidth: 980px;

.grid {
  font-size: 0;
  text-align: left;
  max-width: $contentGridCustomMaxWidth;
  margin: 0 auto;
}

.gridSquare {
  display: inline-block;
  width: 50%;
  border: 1px solid white;
  position: relative;
  @include gradient-content-grid();
  @include backgroundAttachmentCoverCenter();
  padding-bottom: 50%;

  &.gridText {
    width: 100%;
    padding-bottom: 40%;
  }


  @include breakpoint(phablet-up) {
    width: calc(100% / 3);
    padding-bottom: calc(100% / 4);
    &.gridText {
      width: calc(100% / 3);
      padding-bottom: calc(100% / 4);
    }
  }
}

.gridSquare > * {
  text-align: left;
  position: absolute;
  @include centerBothAbsolute();
  width: 75%;
}

.gridSquare > p {
  text-align: center;
  font-family: $proxima;
  color: white;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  @include breakpoint(laptop-up) {
    font-size: 20px;
  }
}

.link {
  border-bottom: 0 solid white;
  &:hover {
    border-bottom-width: 1px;
  }
}

.gridSquare:not(.gridText):before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(12,20,28,0.4);
}

