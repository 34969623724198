@import '~styles/_breakpoint-variables';
@import '~styles/_breakpoints';
@import '~styles/_borders';

$narrow: 740px;

.sectionWrapper {
  margin: 0 auto;
  max-width: $desktop-min;
  position: relative;

  @include breakpoint(mobile-only) {
    padding: 0 $padding-mobile;
  }

  @include breakpoint(tablet-only) {
    padding: 0 $padding-tablet;
  }

  @include breakpoint(laptop-only) {
    padding: 0 $padding-laptop;
  }

  @include breakpoint(desktop) {
    padding: 0 $padding-desktop;
  }
}

.sectionOverride {
  @include breakpoint(mobile-only) {
    padding: 0 $padding-mobile;
    display: flex;
    flex-direction: column-reverse;
    //border: 1px solid $greyText;
    //margin: 0 16px;
  }
}

/* Section.props.mobileNoPadding */
.mobileNoPadding {
   @include breakpoint(mobile-only) {
     padding: 0;
   }
}

/* Section.props.tabletNoPadding */
.tabletNoPadding {
  @include breakpoint(tablet-only) {
    padding: 0;
  }
}

/* Section.props.narrow */
.narrow {
  max-width: $narrow;
}
