@import '~styles/_fonts';
@import '~styles/_colors';
@import '~styles/_breakpoints';

.contact {
  font-family: $proxima;
  letter-spacing: -0.25px;
  p {
    margin: 0;
    line-height: 1.4;
  }
}

.contact.xs,
.contact.sm,
.contact.md {
  .role {
    display: none;
  }
}

.contact.xs {
  font-size: 16px;
  color: $greyDark;
}

.contact.lg {
  font-size: 16px;
  font-weight: 400;

  .bold {
    font-weight: 700;
    color: $greyDark;
  }
}

.byline {
  font-weight: 500;
}

.nameItalic {
  .name {
    font-family: $freight;
    font-style: italic;
  }
}

.contact.testimonial {
  font-size: 14px;
  .institution {
    color: $greyTextDark;
    font-style: italic;
  }
}
