@import '~styles/_fonts';
@import '~styles/_colors';
@import '~styles/_breakpoints';
@import '~styles/_spacing';
@import '~styles/_mixins';
@import '~styles/_zindex';
@import '_mixins';

.header {
  height: $globalHeaderHeightMobile;
  padding: 10px 0;
  @include breakpoint (tablet-up) {
    height: $globalHeaderHeight;
    padding: 17px 0;
  }
  font-family: $proxima;
  color: white;
  font-size: 16px;
  font-weight: 500;
  background-color: $navySecondary;
}

.transparent {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: none;
  color: white;
  z-index: $zTransparentHeader;
  svg > * {
    stroke: white;
  }
}

.transparentPadding {
  padding-top: 85px;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: -12px;
}

.logo {
  margin-top: 5px;
  margin-right: 30px;
  img {
    width: 185px;
  }
}

.navigation {
  &:not(.loggedIn) {
    flex-grow: 1;
    > * {
      margin: 0 auto;
    }
  }
}

.right {
  margin-left: auto;
  > * {
    display: inline-block;
    vertical-align: middle;
  }

  .signInButton {
    display: none;
    @include breakpoint(laptop-up) {
      display: inline-block;
    }
  }

  .icon {
    svg {
      // Need Marty to export SVGs as expanded assets; so we affect fill, and not stroke...
      stroke: white;
    }

    @include linkHover();
    &:hover {
      cursor: pointer;
    }

    vertical-align: middle;
    padding: 5px 14px 0px;
    @include breakpoint (tablet-up) {
      margin-top: 7px;
    }
  }

  .hamburger {
    display: inline-block;
    @include breakpoint(laptop-up) {
      display: none;
    }
  }
}

.joinButton {
  font-family: $proxima;
  font-weight: 600;
  font-size: 13px;
  color: white;
  border: 2px solid white;
  background-color: transparent;

  position: relative;
  min-width: 186px;
  height: 39px;
  margin: 0 10px;
  padding: 0;
  transition: 0.2s all;
  &:hover {
    cursor: pointer;
  }

  display: none;
  @media (min-width: $tablet-min + 70px) {
    display: block;
  }
}

@mixin accountText () {
  color: inherit;
  font-size: 14px;
  font-weight: 400;
  @include linkHover();
}

.signInButton {
  @include buttonReset;
  margin: 0 10px;
  @include accountText;
  outline: none;
  transition: 0.2s all;
  &:hover {
    cursor: pointer;
  }
}

.loadingSpinner {
  display:block;
  margin: 0 auto;

  margin-top: 5px;
  width: 30px;
  height: 30px;

  @include breakpoint (tablet-up) {
    width: 36px;
    height: 36px;
  }
}
