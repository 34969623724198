@import '_breakpoint-variables';

@mixin breakpoint($point) {
  /*
   * This breakpoint should be used sparingly -- brought in solely
   * to assist retrofitting our <Grid /> with mobile styles. All other
   * styles should be declared mobile-first.
   */
  @if $point == mobile-only {
    @media (max-width: $mobile-max) { @content ; }
  }
  @else if $point == phablet-only {
    @media (min-width: $phablet-min) and (max-width: $phablet-max) { @content ; }
  }
  @else if $point == phablet-up {
    @media (min-width: $phablet-max) { @content ; }
  }
  @else if $point == tablet-up {
     @media (min-width: $tablet-min) { @content ; }
  }
  @else if $point == tablet-only {
    @media (min-width: $tablet-min) and (max-width: $tablet-max) { @content ; }
  }
  @else if $point == laptop-up {
     @media (min-width: $laptop-min) { @content ; }
  }
  @else if $point == laptop-only {
     @media (min-width: $laptop-min) and (max-width: $laptop-max) { @content ; }
  }
  @else if $point == desktop {
    @media (min-width: $desktop-min) { @content ; }
  }
}
