@import '~styles/_borders';
@import '~styles/_breakpoints';
@import '~styles/_breakpoint-variables';
@import '~styles/_colors';
@import '~styles/_mixins';
@import '~styles/_zindex';

$sectionPadding: 40px;

.wrapper {
  text-align: center;
  padding: $padding-mobile 0;
  position: relative;

  &:nth-child(2n) {
    //background-color: $greyBkgLight;
    @include border-cards();
    @include breakpoint(mobile-only) {
      border: none;
      border-bottom: 1px solid #dedede;

    }
  }

  @include breakpoint(tablet-up) {
    padding: $padding-tablet 0;
  }

  @include breakpoint(laptop-up) {
    padding: $padding-laptop 0;
  }

  @include breakpoint(laptop-up) {
    padding: $padding-desktop 0;
  }
}

.title {
  max-width: 600px;
  margin: 0 auto 20px auto;
  @include breakpoint(tablet-up) {
    margin-bottom: 35px;
  }
  p {
    font-size: 15px;
    margin-top: 10px;
  }
}

.backgroundImage {
  padding: $sectionPadding 0;
  @include backgroundAttachmentCover();
  background-position: 75% 50%;

  @include breakpoint (tablet-up) {
    padding: 60px 0;
    background-position: 50% 50%;
  }
}

/* CMSSection.props.mobileBannerImage */
.mobileBannerOverride {
  @include breakpoint(mobile-only) {
    background-image: none !important;
  }
}

.bannerImage {
  @include breakpoint(mobile-only) {
    height: 200px;
    @include backgroundAttachmentCoverCenter();
    padding: 0;
    margin-bottom: 30px;
  }
}

.bannerImageMargin {
  @include breakpoint(mobile-only) {
    margin-right: -#{$padding-mobile};
    margin-left: -#{$padding-mobile};
    margin-top: -#{$sectionPadding};
  }
}

.backgroundImage.noBkgMask {
  color: white;
}

/* CMSSection.props.backgroundMask */
.gradientWhite:not(.noBkg):after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include gradient-white();
}

.gradientWhite.mobileBannerOverride:after {
  @include breakpoint(mobile-only) {
    background: none;
  }
}

/* Only override natural page layers when there is a background mask present */
.wrapper:not(.noBkgMask) > * {
  @include breakpoint (tablet-up) {
    z-index: $zResetContentAbovePseudoMask;
  }
}

/* CMSSection.props.noBorder */
.wrapper.noBorder {
  border: none;
}

/* CMSSection.props.zIndexPriority */
.zIndexPriority > * {
  z-index: $zCMSPriority;
}
