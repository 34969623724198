@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';

.loginModalContainer {
  @include breakpoint (tablet-up) {
    width: 380px;
  }
  @include breakpoint (laptop-up) {
    width: 477px;
  }
}

.errorText {
  font-family: $proxima;
  font-size: 14px;
  margin: 0.2em 0 0.6em;
  height: 1em;
}

.contactUsLink {
  color: $bluePrimary;
}

.link {
  text-align: center;
}
