@import '~styles/_breakpoints';

.spacer {
  height: 1px;
}

/* Sizes */

.xl {
  padding-top:50px;
  padding-bottom: 50px;

  @include breakpoint(laptop-up) {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.lg {
  padding-top: 40px;
  padding-bottom: 40px;

  @include breakpoint(laptop-up) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.md {
  padding-top: 25px;
  padding-bottom: 25px;

  @include breakpoint(laptop-up) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.sm {
  padding-top: 15px;
  padding-bottom: 15px;

  @include breakpoint(laptop-up) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.xs {
  padding-top: 10px;
  padding-bottom: 10px;

  @include breakpoint(laptop-up) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.xxs {
  padding-top: 5px;
  padding-bottom: 5px;

  @include breakpoint(laptop-up) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
