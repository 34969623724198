$behind: -1;
$none: 0;
$low: 1;
$mid: 2;
$high: 3;
$highest: 4;

$zFormWizardCircleLayer: $low;
$zFilterTabsPseudo: $low;
$zInsitutionLandingContact: $low;
$zResetContentAbovePseudoMask: $low;
$zFlashMessage: $mid;
$zCMSPriority: $mid;
$zTransparentHeader: $mid;
$zDropdownMenu: $high;
$zMobileMenu: $high;
$zModal: $highest;
