@import '~styles/_colors';
@import '~styles/_shadows';

.wrapper {
  width: 600px;
  max-width: 100%;
}

.subaccountList {
  border-collapse: collapse;
  background-color: white;
  margin-bottom: 16px;
  font-size: 15px;

  @include shadow-cards();

  th {
    font-weight: 500;
  }

  td, th {
    border: 1px solid $greyText;
    padding: 8px 20px;
  }
}
