@import '~styles/_breakpoints';
@import '~styles/_colors';

.formSection {
  border-bottom: 1px solid $greyText;

  padding: 20px 20px;
  @include breakpoint(laptop-up) {
    padding: 30px 80px;
  }

  background-color: white;

  &.grey {
    background-color: $greyBkgLight;
  }

  &.blue {
    background-color: #383cbc;
    color: white;
  }
}
