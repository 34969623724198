$tablet-min-width: 425px;
$laptop-min-width: 960px;
$desktop-min-width: 1120px;
// for when you need something a little less
// than tablet, but a little more than mobile
$phablet-max-width: 699px;

$padding-mobile: 16px;
$padding-tablet: 24px;
$padding-laptop: 28px;
$padding-desktop: 30px;

$mobile-max: $tablet-min-width - 1 + ($padding-mobile * 2);
$phablet-min: $mobile-max + 1;
$phablet-max: $phablet-max-width + ($padding-tablet * 2);
/*
 * Because "phablet" is a section of the tablet range, there is no true start/stop
 * to this range. However, there is no true "phablet-max" -- so we need this in
 * order to use it with MediaQuery, to denote a 1px diff of where the range "stops."
 */
$phablet-max-plus-one: $phablet-max + 1;
$tablet-min: $phablet-min;
$tablet-max: $laptop-min-width - 1 + ($padding-tablet * 2);
$laptop-min: $tablet-max + 1;
$laptop-max: $desktop-min-width - 1 + ($padding-desktop * 2);
$desktop-min: $laptop-max + 1;
