@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_zindex';

$wizardCircleRadius: 7px;
$transitionTime: 0.25s;

.wizardProgress {
  background-color: $greyBkgLight;
  border-bottom: 1px solid $greyText;
  position: relative;
  display: none;
  @include breakpoint(laptop-up) {
    display: block;
  }
  padding: 30px 70px;
  margin: 0;
  text-align: center;
}

.wizardProgressItem {
  display: inline-block;
  text-align: center;
  position: relative;
  width: 190px;
}

.wizardLink {
  position: relative;
  display: inline-block;
  text-align: center;
  color: $greyTextDark;
  font-size: 16px;
  font-weight: 300;
  width: 100%;
  white-space: nowrap;
}

.disabled :global(a) {
  pointer-events: none;
  cursor: default;
}

.wizardLink:hover {
  background-color: transparent;
}

.earlierStep .wizardLink {
  color: $blackPrimary;
  font-weight: bold;
}

.wizardLinkActive {
  color: $bluePrimary !important;
  font-weight: bold;
}

/* Circle */
.wizardCircleWrapper {
  position: relative;
  display: block;
  margin: 12px auto 0;
  width: ($wizardCircleRadius * 2) + 8;
  height: ($wizardCircleRadius * 2) + 8;
  z-index: $zFormWizardCircleLayer;
  transition: background-color $transitionTime;
  border-radius: $wizardCircleRadius + 8;
}

.wizardLinkActive .wizardCircleWrapper { /* For transparent border */
  background-color: transparentize($bluePrimary, 0.8);
}

.wizardCircle {
  content: "";
  position: relative;
  top: 4px;
  left: 4px;
  display: block;
  width: $wizardCircleRadius * 2;
  height: $wizardCircleRadius * 2;
  background-color: $greyText;
  border-radius: $wizardCircleRadius;
  transition: background-color $transitionTime;
}

.wizardLinkActive .wizardCircle,
.earlierStep .wizardCircle {
  @extend .wizardCircle;
  background-color: $bluePrimary;
}

/* Line */
.wizardLine {
  position: absolute;
  top: 39px;
  left: 0;
  border: 1px solid $greyText;
  color: transparent;
  transition: border $transitionTime;
}

.wizardLineLeft {
  @extend .wizardLine;
  width: 50%;
  right: 50%;
}

.wizardLineRight {
  @extend .wizardLine;
  width: 50%;
  left: 50%;
}

.wizardProgressItem:first-child .wizardLineLeft,
.wizardProgressItem:last-child .wizardLineRight {
  display: none;
}

.wizardLinkActive .wizardLineLeft,
.earlierStep .wizardLineLeft,
.earlierStep :not(.wizardLinkActive) .wizardLineRight {
  border-color: $bluePrimary;
}
