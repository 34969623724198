@import '~styles/_breakpoints';
@import '~styles/_mixins';

.cardWrapper {
  margin-bottom: 20px;
  position: relative;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.card {
  padding: 16px;
}

.content {
  @include breakpoint(phablet-up) {
    padding-right: calc(50% + 40px);
    text-align: left;
  }
}

.headerWrapper {
  margin-bottom: 24px;
}

.image {
  // removing margin to run to edge of Card
  margin: -32px -32px 30px;
  height: 200px;
  @include backgroundAttachmentCoverCenter;

  @include breakpoint(phablet-only) {
    margin: -40px -40px 30px;
  }

  @include breakpoint(phablet-up) {
    margin: 0;
    position: absolute;
    width: 50%;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
  }
}

.buttonWrapper {
  margin-top: 36px;

  @include breakpoint(phablet-up) {
    width: 50%;
  }
  @include breakpoint(laptop-up) {
    width: 40%;
    margin-top: 44px;
  }
}

.copy p {
  margin: 0;
}
