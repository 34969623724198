@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_shadows';

@mixin section-width {
  width: 100%;
  @include breakpoint (laptop-up) {
    width: 500px;
  }
}

.metrics {
  border-collapse: collapse;
  background-color: white;
  margin-bottom: 16px;
  font-size: 15px;

  @include section-width;

  @include shadow-cards();

  td {
    border: 1px solid $greyText;
    padding: 8px 20px;
    width: 50%;
  }

  td:first-child {
    text-align: right;
    font-weight: 500;
  }
}

.breakdownChartWrapper {
  width: 100%;
  text-align: center;
}
