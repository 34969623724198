@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';

.wrapper {
  text-align: center;

  padding: 20px 0;
  @include breakpoint(tablet-up) {
    padding: 35px 30px;
  }
  @include breakpoint(laptop-up) {
    padding: 70px 90px;
  }
}

.text {
  font-family: $freight;
  margin-bottom: 10px;

  font-size: 16px;
  @include breakpoint(tablet-up) {
    font-size: 20px;
  }
  @include breakpoint(laptop-up) {
    font-size: 24px;
    padding: 0 15px;
    margin-top: 40px;
  }
}
