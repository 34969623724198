@import './_colors';

$borderCard: 1px solid $greyText;

@mixin border-thin($property) {
  border-#{$property}: $borderCard;
}

@mixin border-dashboard($property) {
  @include border-thin($property);
}

@mixin border-cards() {
  border: $borderCard;
}

@mixin border-input() {
  border: 2px solid $greyText;
  border-radius: 2px;
}
