@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_zindex';

/* InstitutionLandingHeader */

.headerContainer {
  text-align: center;
  padding: 120px 0 60px;
  @include breakpoint(laptop-up) {
    padding: 189px 0 96px;
    text-align: left;
  }
}

.activateButtonContainer {
  max-width: 350px;
  margin: 0 auto;
  @include breakpoint(laptop-up) {
    margin: 0 auto;
  }
}

/* InstitutionLandingContact */

.landingContactContainer {
  @include breakpoint(laptop-up) {
    position: absolute;
    z-index: $zInsitutionLandingContact;
    margin-top: 3%;
    right: 30px;
    width: 315px;
  }
}

.contactAvatarWrapper {
  margin-bottom: 10px;
  @include breakpoint(laptop-up) {
    //margin-top: -68px;
  }
}

.contactSection {
  color: $greyDark;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  padding: 23px;
  text-align: center;
  border-left: none;
  //border-bottom: $borderStyle;
  @include breakpoint(laptop-up) {
    //border: $borderStyle;
    border-left: 1px solid #dedede;
  }
  // &.mainContactSection {
  //   background-color: $greyBkgLight;
  //   border-top: $borderStyle;
  // }
}

.contactSectionLabel {
  display: block;
  font-size: 16px;
  font-weight: 600;
  font-family: $proxima;
}

/* InstitutionLandingForward */
.landingForwardContent {
  text-align: left;
  padding: 10px 0 12px;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.45;
  @include breakpoint(laptop-up) {
    max-width: 700px;
    font-size: 20px;
    padding: 45px 0 52px;
  }
  p:last-child {
    line-height: 1.67;
    @include breakpoint(laptop-up) {
      font-size: 18px
    }
  }
}

/* InstitutionLandingFeatures */

.landingFeaturesWrapper {
  $borderStyle: 1px solid $greyText;
  border-top: $borderStyle;
  //border-bottom: $borderStyle;
}

.landingFeaturesContainer {
  padding: 68px 0;
}

/* InstitutionalLandingClaimCTA */
.landingClaimCTAContainer {
  padding: 60px 0;
  text-align: center;
}

.landingClaimCTAItem {
  display: inline-block;
  margin: 0 20px;
}

.landingClaimCTAItem p {
  font-size: 24px;
  font-family: $proxima;
}

.landingClaimCTAItem button {
  width: 220px;
  @include breakpoint(laptop-up) {
    width: 350px;
  }
}

.forward {
  margin-top: 0;
  font-style: italic;
}

.quote {
    font-family: $freight;
    display: inline-block;
    font-size: 60px;
    margin: 0;
    line-height: 0.5;
    font-weight: 600;
    color: #151E6A;
}
