@import '~styles/_breakpoints';
@import '~styles/_fonts';

.wrapper {
  padding: 30px 0 20px;

  @include breakpoint(tablet-up) {
    padding-top: 40px;
    padding-bottom: 30px;
  }

  @include breakpoint(laptop-up) {
    padding-top: 60px;
  }
}

.time {
  font-family: $proxima;
  margin-bottom: 30px;
}

.byline {
  margin-top: 30px;
}
