@import '~styles/_colors';
@import '~styles/_breakpoints';
@import '~styles/_fonts';

.wrapper {
  max-width: 800px;
  margin: 0 auto;
}

.cardWrapper {
  padding: 24px;

  @include breakpoint(tablet-up) {
    padding: 10px;
  }

  @include breakpoint(phablet-up) {
    padding-right: 10%;
  }
}

.wrapper p {
  margin: 0;
}

.name,
.detail {
  @include breakpoint(tablet-up) {
    text-align: left;
  }
}

.detail,
.price {
  font-family: $proxima;
}

.name {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
  line-height: 1.4;

  @include breakpoint(tablet-only) {
    margin-bottom: 16px;
    font-size: 16px;
  }
}

.detail {
  font-size: 16px;
  margin-bottom: 40px;

  @include breakpoint(tablet-only) {
    font-size: 14px;
  }
}

.price {
  font-size: 56px;
  margin-bottom: 16px;

  @include breakpoint(tablet-only) {
    font-size: 32px;
  }
}

.originalPrice {
  font-size: 32px;

  text-decoration: line-through;
  margin-right: 10px;
}

.unit {
  font-size: 18px;
  font-style: italic;
  color: $greyTextDark;
  font-weight: 500;

  @include breakpoint(tablet-only) {
    font-size: 16px;
  }
}

.detail,
.unit {
  @include breakpoint(tablet-up) {
    margin-bottom: 0;
  }
}

.price,
.unit {
  /* Have to hack around some Combo restraints 😤 */
  line-height: 1;
}
