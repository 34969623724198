@import '~styles/_fonts';
@import '_vars';

@mixin listReset () {
  list-style-type: none;
  padding: 0;
}

$menuPadding: 22px;

.menu {
  font-size: 16px;
  font-weight: 300;
  @include listReset();
  padding: 0 25px;
  margin: 0;
  > li {
    .submenuArrow {
      @media (min-width: $tabletMenuBreakpoint) {
        display: none;
      }
      transition: transform 200ms ease-in-out;
      margin-top: 10px;
      float: right;
    }

    cursor: pointer;
    padding: 0;
    border-bottom: 1px solid transparentize(white, 0.7);
    &:last-child {
      border-bottom: none;
    }

    > * {
      // centralize link or button styles
      display: block;
      padding: $menuPadding 0;
      width: 100%;
      height: 100%;
      font-size: inherit;
      font-weight: 300;
      color: inherit;
      text-align: left;
      border: 0;
      background-color: transparent;
      outline: none;
      font-family: $freight;
      font-size: 21px;
      font-weight: 500;
    }

    .submenu {
      transition: height 300ms ease-in-out;
      overflow: hidden;
      font-family: $proxima;
      font-weight: 500;
      font-size: 16px;
      @include listReset();
      position: relative;

      @media (min-width: $tabletMenuBreakpoint) {
        column-count: 2;
      }

      > li {
        @media (min-width: $tabletMenuBreakpoint) {
          break-inside: avoid;
        }
        &:before {
          content: '–';
          position: absolute;
          color: transparentize(white, 0.5);
          margin-left: -20px;
          margin-top: 0;
        }
        padding: 12px $menuPadding;
        &:first-child {
          padding-top: 10px;
        }
        &:last-child {
          padding-bottom: 10px;
        }
      }
    }

    .submenuMenu {
      @include listReset();
      font-weight: 300;
      font-size: 15px;
      li {
        > * {
          display: block;
          position: relative;
          padding: 6px 0;
        }
      }
    }

    &.submenuOpen {
      .submenuArrow {
        transform: rotate(180deg);
      }
    }

    @media (max-width: $tabletMenuBreakpoint - 1) {
      &:not(.submenuOpen) .submenu {
        height: 0 !important;
      }
    }

    @media (min-width: $tabletMenuBreakpoint) {
      .submenu {
        height: auto !important;
      }
    }
  }
}
