@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_mixins';
@import '~styles/_zindex';

.flash {
  font-family: $proxima;
  font-weight: 500;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $redPrimary;
  text-align: center;
  color: white;
  padding: 20px;
  overflow: hidden;
  z-index: $zFlashMessage;
}

.dismiss {
  display: inline-block;
  background-color: transparent;
  border: none;
  color: white;
  font-family: $proxima;
  font-weight: 500;
  font-size: 18px;
  position: absolute;
  padding: 20px;
  right: 20px;
  @include centerYAbsolute();
  &:hover {
    cursor: pointer;
  }
}
