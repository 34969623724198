@import '~styles/_fonts';

.article p {
  margin: 0;
}

/* ArticleBody.props.left */
.left {
  text-align: left;
}

/* ArticleBody.props.proxima */
.proxima {
  font-family: $proxima;
}
