@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_mixins';
@import '~styles/_spacing';
@import '~styles/_zindex';

$primaryHeight: 500px;
$primaryPaddingTop: 100px;

.wrapper {
  text-align: center;
  padding: 50px 30px;
  position: relative;
  // To make up for the height of the global header
  padding-top: calc(#{$globalHeaderHeightMobile} + 50px);
  padding-bottom: 70px;

  @include breakpoint(tablet-up) {
    padding-top: calc(#{$globalHeaderHeight} + 50px);
    text-align: left;
  }
}

/* Generating background mask */
.wrapper:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include gradient-navy();
}

/* Ensuring background mask doesn't mask content */
.wrapper > * {
  z-index: $zResetContentAbovePseudoMask;
}

.backgroundImage {
  z-index: $none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include backgroundAttachmentCoverCenter();
  filter:grayscale(30%);
  -webkit-filter:grayscale(30%);
  -moz-filter:grayscale(30%);
  -o-filter:grayscale(30%);
  -ms-filter:grayscale(30%);
}

.heroWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include breakpoint(laptop-up) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.hero {
  @include breakpoint(laptop-up) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.heading {
  margin-bottom: 36px;

  @include breakpoint(tablet-only) {
    max-width: 90%;
    width: 500px;
  }

  @include breakpoint(laptop-up) {
    width: 650px;
    flex-grow: 2;
  }
}

.subtitle {
  margin: 20px 0 0;
  color: white;
  font-size: 34px;
}

.ctaWrapper {
  display: flex;
  gap: 20px;
}

.cta {
  max-width: 250px;
  margin: 0 auto;

  @include breakpoint(tablet-up) {
    margin: 0;
  }

  @include breakpoint(laptop-up) {
    max-width: 330px;
  }
}

@keyframes rollDown {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(120%);
  }
  51% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes rollRight {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(120%);
  }
  51% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.button {
  width: fit-content;
  margin: 0 auto;
  padding: 5px 35px;

  .buttonIcon {
    width: 13px;
    height: 13px;
    flex-shrink: 0;
    overflow: hidden;
  }
  &:hover {
    .buttonIcon {
      :global(.download-arrow-path) {
        animation: rollDown .5s ease-in-out;
      }

      :global(.arrow-right-path) {
        animation: rollRight .5s ease-in-out;
      }
    }
  }
}

.secondaryButton {
  color: white;
  &:hover {
    color: $orangePrimaryHover;
  }
}

.buttonLabel {
  display: flex;
  align-items: center;
  gap: 10px;
}
