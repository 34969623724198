@import '~styles/_mixins';
@import '~styles/_zindex';

@mixin linkHover () {
  transition: 0.2s all;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}

@mixin buttonReset () {
  background: none;
  border: none;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
}

@mixin dropdownMenuButton ($classname) {
  &:hover {
    cursor: pointer;
    .#{$classname} {
      visibility: visible;
      opacity: 1;
      cursor: initial;
    }
  }
}

@mixin dropdownMenuWrapper ($menuWidth) {
  opacity: 0;
  visibility: hidden;
  transition: 0.2s all;
  position: absolute;
  width: $menuWidth;
  z-index: $zDropdownMenu;
}

@mixin dropdownMenuWrapperAndMargin ($menuWidth, $marginLeft) {
  @include dropdownMenuWrapper($menuWidth);
  margin-left: $marginLeft;
}

@mixin dropdownMenuContent () {
  @include listReset;
  position: relative;
  margin-top: 18px;
  color: $blackPrimary;
  background: white;
  box-shadow: 0 0 5px $boxShadowMenu;
  -webkit-backface-visibility: hidden;

  &:before {
    // The lil triangle pointing back at parent
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: -4px;
    transform: rotate(135deg);
    border-left: 10px solid white;
    border-bottom: 10px solid white;
    box-shadow: -3px 2px 2px transparentize($boxShadowMenu, 0.2);
  }
}

@mixin dropdownMenuContentArrowCenter ($menuWidth) {
  @include dropdownMenuContent();
  &:before {
    left: calc(#{$menuWidth}/2 - 4px);
  }
}

@mixin dropdownMenuContentAccount ($arrowRight) {
  @include dropdownMenuContent();
  &:before {
    right: $arrowRight;
  }
}
