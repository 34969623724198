$breakpoint: 779px;

@mixin small-screen {
  @media (max-width: $breakpoint) { @content; }
}

@mixin large-screen {
  @media (min-width: $breakpoint + 1) { @content; }
}

