@import '~styles/_fonts';
@import '~styles/_breakpoints';

.selectInstitutionSection {
  text-align: left;
  margin: 0 auto;

  @include breakpoint (laptop-up) {
    max-width: 550px;
  }

  @include breakpoint (tablet-only) {
    max-width: 416px;
    text-align: center;
  }

  p {
    font-size: 13px;
    max-width: 340px;

    @include breakpoint (tablet-up) {
      max-width: 100%;
    }

    @include breakpoint (laptop-up) {
      font-size: 16px;
    }

    font-family: $proxima;
    font-weight: 400;
    line-height: 1.81;
  }

  form {
    margin-top: 50px;
    @include breakpoint (tablet-only) {
      text-align: center;
      max-width: 348px;
      margin: 0 auto;
      margin-top: 40px;
    }
    @include breakpoint (laptop-up) {
      margin-top: 64px;
    }
  }

  button {
    margin-top: 10px;
    @include breakpoint (laptop-up) {
      margin-top: 20px;
    }
  }
}

.heading {
  @include breakpoint (tablet-up) {
    text-align: center;
  }
}
