@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_mixins';
@import '~styles/_spacing';

.avatar {
  margin: 25px 0;
  @include breakpoint (tablet-up) {
    margin: 25px auto;
    text-align: center;
  }
}

.button {
  max-width: 230px;
  @include breakpoint (tablet-up) {
    margin: 0 auto;
  }
}
