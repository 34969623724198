@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_spacing';

@mixin footer-tablet-up {
  @media (min-width: 772px) {
    @content;
  }
}

@mixin pipeSpacer () {
  &:after {
    content: '|';
    margin-left: 10px;
    margin-right: 10px;
  }
}

$footerHeightMobile: 557px;
$footerHeightTablet: 322px;
$footerHeightDesktop: 395px;

// Height changes if authenticated
$footerHeightMobileAuth: 514px;
$footerHeightTabletAuth: 279px;
$footerHeightDesktopAuth: auto;

@mixin footerHeights ($property, $authenticated) {
  @if $authenticated {
    #{$property}: $footerHeightMobileAuth;

    @include footer-tablet-up {
      #{$property}: $footerHeightTabletAuth;
    }

    @include breakpoint(laptop-up) {
      #{$property}: $footerHeightDesktopAuth;
    }
  } @else {
    #{$property}: $footerHeightMobile;

    @include footer-tablet-up {
      #{$property}: $footerHeightTablet;
    }

    @include breakpoint(laptop-up) {
      #{$property}: $footerHeightDesktop;
    }
  }
}

.spacer {
  /* Generate padding to ensure page content doesn't go behind pos: absolute footer */
  @include footerHeights(padding-top, false);
}

/* Footer.js */
.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  /* Hard-coding heights in order to generate "sticky" footer */
  @include footerHeights(height, false);
}

.authenticated {
  .spacer {
    @include footerHeights(padding-top, true);
  }

  .footer {
    @include footerHeights(height, true);
  }
}

.footerTop {
  background-color: $purplePrimary;
  text-align: center;
  @include section-padding();
}

.footerBottom {
  background-color: $navySecondary;
  text-align: center;
  @include section-padding();
  @include footer-tablet-up {
    padding: 20px 0;
  }
}

$logoWidth: 185px;

.footerBottomGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  > * {
    width: 100%;
  }
  @include footer-tablet-up {
    > :nth-child(1), > :nth-child(3) {
      width: $logoWidth;
      text-align: left;
    }
    > :nth-child(2) {
      width: calc(100% - (#{$logoWidth} * 2));
    }
  }
}

.logo {
  margin: 10px 0 30px;
  width: $logoWidth;
  @include footer-tablet-up {
    margin: 0;
  }
}

/* components/FooterContactUs.js */
.contactUs {
  color: white;
}

/* components/FooterSubscribe.js */
.subscribe {
  color: white;
  opacity: 0.7;
  cursor: pointer;
  font-family: $proxima;
  font-size: 14px;
  font-weight: 300;
  text-decoration: underline;
  @include breakpoint (laptop-up) {
    font-size: 16px;
  }
}

/* components/FooterContactDetails.js */
.contactDetails {
  font-size: 13px;
  color: transparentize(white, 0.4);
  font-family: $proxima;
  padding-bottom: 40px;
  @include footer-tablet-up {
    font-size: 12px;
    padding-bottom: 0;
  }
  @include breakpoint (laptop-up) {
    font-size: 14px;
  }
}

.break {
  display: block;
  padding-bottom: 10px;
  @include breakpoint (laptop-up) {
    padding-bottom: 0;
    display: inline-block;
  }
}

.legalWrapper {
  display: inline-block;
  @include pipeSpacer;
}

.mailToLink {
  color: transparentize(white, 0.4);
  text-decoration: none;
  &:hover {
    color: white;
  }
}

.email {
  @include breakpoint (laptop-up) {
    @include pipeSpacer;
  }
}

.email,
.phone{
  display: inline-block;
}

/* components/FooterSocial.js */
.social {
  @include footer-tablet-up {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
  }
}

.social img {
  height: 20px;
  margin: 10px 15px;
  @include footer-tablet-up {
    /*
     * Hyper-specific margining to get vertical alignment tighter
     * between social icons and contact details
     */
    margin: 12px 10px 6px;
  }

  @include breakpoint (laptop-up) {
    height: 24px;
  }
}

.legal {
  color: white;
  opacity: 0.6;
  &:hover {
    opacity: 0.8;
  }
}
