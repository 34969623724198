@import '~styles/_borders';
@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';
@import '~styles/_spacing';

.eventDate,
.eventTime,
.eventType {
  margin: 0;
  font-size: 14px;
  font-family: $proxima;
}

.eventDate,
.eventTime {
  display: inline-block;
  color: $greyTextDark;
  font-size: 14px;
  margin-bottom: 10px;
  &:last-child {
    margin-left: 8px;
  }

  @include breakpoint(tablet-only) {
    font-size: 16px;
  }

  @include breakpoint(laptop-up) {
    display: block;
    margin-bottom: 0;
    &:last-child {
      margin-left: 0;
    }
  }
}

.eventTime {
  margin-left: 8px;
  @include breakpoint(laptop-up) {
    margin-left: 0;
  }
}

.eventDate,
.eventType {
  @include breakpoint(tablet-up) {
    font-size: 16px;
    color: $blackPrimary;
  }
}

.eventRegister {
  padding: $padding-mobile;
  margin: -#{$padding-mobile};
  margin-top: $padding-mobile;
  @include border-dashboard(top);

  @include breakpoint(tablet-only) {
    padding: $padding-tablet;
    margin: -#{$padding-tablet};
    margin-top: $padding-tablet;
  }

  @include breakpoint(laptop-up) {
    padding: 0;
    margin: 0;
    border-top: none;
  }
}

.registered {
  font-family: $proxima;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  margin-top: 7px;
}

.liveIndicator {
  font-family: $proxima;
  font-size: 14px;
  font-weight: 400;
  color: $redPrimary;
  display: inline;
  padding-left: 10px;

  @include breakpoint(laptop-up) {
    display: block;
    padding-left: 0;
  }

  &:before {
    content: '•';
    font-size: 16px;
    padding-right: 4px;
  }
}
