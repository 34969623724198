@import '~styles/_breakpoints';
@import '~styles/_colors';
@import '~styles/_fonts';


@mixin smallView {
  @media (max-height: 700px) {
    .envelopeImage {
      padding: 5px 0 0 5px !important;
      width: 50px !important;
      height: 50px !important;
    }
    .stayInformedText {
      font-size: 16px !important;
    }

    .checklistText {
      font-size: 14px !important; 
    }

    .subscribeText {
      font-size: 14px !important; 
    }
    .stayInTheLoopTopHalf {
      padding: 10px 5px 10px 5px !important;
    }
    .stayInTheLoopLowerHalf {
      padding-top: 3px !important;
    }
    .firstDash {
      display: none !important;
    }
    .secondDash {
      display: none !important;
    }
  }
}

@include breakpoint(mobile-only) {
  @include smallView;
}

@include breakpoint(phablet-only) {
  @include smallView;
}

.modalWrapper {
  @include breakpoint(phablet-up) {
    width: 578px;
  }
}

.stayInTheLoopTopHalf {
  color: white;
  padding: 18px 34px 34px 30px;
  background-color: #383cbc;
}

.stayInTheLoopLowerHalf{
  padding: 30px 30px 0 30px;
}

.stayInformedFlexContainer {
  display: flex;
  flex-flow: column-reverse;
  justify-content: center;
  align-items: center;

  margin: 0;
  padding: 0;
  list-style: none;
  @include breakpoint(phablet-up) {
    flex-flow: row;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.stayInTheLoopTitle {
  display: none;
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #fff;
  @include breakpoint(phablet-up) {
    display: block;
  }
}
.stayInformedText {
  font-weight: 500;
  line-height: 1.4;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #fff;
  max-width: 340px;
  text-align: center;
  @include breakpoint(phablet-up) {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: initial;
  }
}

.checklistText {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.checkmark {
  display: inline-block;
  height: 20px;
  width: 20px;
  @include breakpoint(phablet-up) {
    height: 21px;
    width: 21px;
  }
  border-radius: 50%;
  background-color: $greenPrimary;
  position: relative;
}

.checkmark:after {
  content: '';
  position: absolute;
  border: 1px solid white;
  border-top-width: 0;
  border-right-width: 0;
  height: 4px;
  width: 9px;
  top: 7px;
  left: 6px;
  @include breakpoint(phablet-up) {
    top: 8px;
  }
  transform: rotate(-50deg);
}

.envelopeImage {
  padding: 15px 0 0 10px;
  width: 85px;
  @include breakpoint(phablet-up) {
    padding: 0px;
    width: auto;
  }
}

.checklistItem {
  text-align: left;
  font-family: $proxima;
  padding: 8px 0;
  p {
    margin: 0;
  }
}

.checklistPreText {
  padding:0 0 15px 0;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: none;
  @include breakpoint(phablet-up) {
    display: block;
  }
}

.subscribeText {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.5;
  letter-spacing: normal;

  // css for custom checkbox
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.subscribeText input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.subscribeText span {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  margin: 0 12px 7px 0;
  padding: 5px 4px;
  border-radius: 15%;
  border: solid 1.5px #cfcfcf;
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.subscribeText input:checked ~ span {
  background-color: #131770;
  border-color: #131770
}

/* Create the checkmark/indicator (hidden when not checked) */
.subscribeText span:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.subscribeText input:checked ~ span:after {
  display: block;
}

/* Style the checkmark/indicator */
.subscribeText span:after {
  content: '';
  position: absolute;
  border: 1px solid white;
  border-top-width: 0;
  border-right-width: 0;
  height: 4px;
  width: 9px;
  top: 3px;
  left: 2px;
  transform: rotate(-50deg);
}

.formFooterNote {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #737373;

  display: none;
  padding-bottom: 5px;
  width: 100%;
  font-size: 12px;
  font-weight: normal;
  line-height: 2.5;
  text-align: center;
  @include breakpoint(phablet-up) {
    display: block;
  }
}

.footerNoteWrapper {
  padding: 20px 0;
}

.thankYouFlexContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 90px;
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.subscriptionConfirmed {
  padding: 8px 0 30px 0;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d2d2d;
}

.successEnvelopeImage {
  width: 90px;
}

.successThankYou {
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
}

.errorMessageBox {
  display: inline-block;
  width: 100%;
  margin-bottom: 6px;
  padding: 8px 5px 5px 11px;
  border-radius: 3px;
  background-color: #fbb5b5;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #921313;
}