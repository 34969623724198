@import '~styles/_breakpoints';
@import '~styles/_fonts';

.singleItem {
  text-align: center;
  @include breakpoint(laptop-up) {
    padding: 0 30px;
  }
}

.singleItemImageContainer {
  margin: 20px 0 10px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  max-height: 100%;
}

.copy {
  font-family: $proxima;
  font-size: 14px;
  margin-top: 10px;
}
